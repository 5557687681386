<template>
  <div
    :class="[
      'selection-price', 'full-width-height',
      { 'no-price': !priceValue },
      {'increased': priceValue && displayStatus === 'ON' && changedPrice === 'up'},
      {'decreased': priceValue && displayStatus === 'ON' && changedPrice === 'down'},
    ]"
  >
    <div class="selection-price-value">
      {{ priceValue && displayStatus === 'ON' ? priceValue : '-' }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    priceValue: {
      type: String,
      required: true,
      default: () => '-',
    },
    changedPrice: {
      type: String,
      required: true,
      default: () => '',
    },
    displayStatus: {
      type: String,
      required: true,
      default: () => '',
    },
  },
};
</script>

<style lang="scss">

.full-width-height {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selection-price {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.selection-price.increased {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #00BC57 !important;
  /* background-color: #33C978 !important; */
}

.selection-price.decreased {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #FF2E2D !important;
  /* background-color: #FF5857 !important; */
}
.sgp-disabled {
  background-color: #CDCDCD;
  cursor: not-allowed;
}

.selection-price.no-price {
  color: rgba(#000, 0.25);
}
</style>
