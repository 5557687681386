import { orderBy } from 'lodash';
import { useQuery } from '@vue/apollo-composable';
import { subHours, addHours } from 'date-fns';
import apolloClient from '@/services/apollo-client';
import {
  FOOTBALL_ID,
  BASKETBALL_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} from '@/services/helpers/sports';
import allEventsQuery from './allEvents.query.gql';
import eventQuery from './event.query.gql';
import allSportsQuery from './allSports.query.gql';
import searchQuery from './search.query.gql';
import marketGroupsBySport from './marketGroupsBySport.query.gql';
import sportCompetitions from './sportCompetitions.query.gql';
import operatorsFeaturesQuery from './operatorsFeatures.query.gql';
import allOperatorsQuery from './allOperators.query.gql';
import footballTeamByTeamIdQuery from './footballTeamByTeamId.query.gql';
import basketballTeamByTeamIdQuery from './basketballTeamByTeamId.query.gql';
import marketDisplayConfigurationsQuery from './allMarketDisplayConfigurations.query.gql';
import hockeyTeamByTeamIdQuery from './hockeyTeamByTeamId.query.gql';
import baseballTeamByTeamIdQuery from './baseballTeamByTeamId.query.gql';
import userConfigurationQuery from './userConfiguration.query.gql';

export const queryAllEvents = (options = {}) => apolloClient.query({
  query: allEventsQuery,
  variables: {
    ...options,
    filter: {
      ...(options.filter || {}),
      eventIsCoveredsByEventId: {
        some: {
          available: {
            equalTo: true,
          },
        },
      },
    },
  },
  context: {
    requestTrackerId: 'queryAllEvents',
  },
  fetchPolicy: 'network-only',
});

export const queryEvent = async (eventId, startsAt) => {
  const response = await apolloClient.query({
    query: eventQuery,
    variables: { eventId },
    fetchPolicy: 'network-only',
  });

  if (!startsAt || !response?.data?.event?.startsAt) return response;

  let cutoffDate = startsAt;
  const timezoneHoursOffset = startsAt.getTimezoneOffset() / 60;
  if (timezoneHoursOffset >= 0) {
    cutoffDate = addHours(startsAt, -5);
  } else {
    cutoffDate = subHours(startsAt, 5);
  }

  const cutoffTime = cutoffDate.getTime();
  const eventTime = new Date(response.data.event.startsAt).getTime();
  if (eventTime < cutoffTime) return { data: { event: null } };
  return response;
};

export const queryAllSports = () => apolloClient.query({
  query: allSportsQuery,
  variables: {},
});

export const searchAll = async (searchValue = '', startsAt = new Date().toISOString(), supportedSports = []) => {
  const { data: response = {} } = await apolloClient.query({
    query: searchQuery,
    variables: { searchValue, startsAt, supportedSports },
    context: {
      requestTrackerId: 'searchAll',
    },
  });

  // Sort by event count and get first 10
  const allCompetitors = response?.competitors?.nodes ?? [];
  const allOrderedCompetitors = orderBy(allCompetitors, (({ events }) => events.totalCount), ['desc']);
  const competitors = allOrderedCompetitors.slice(0, 10);

  return {
    data: {
      competitors: {
        nodes: competitors,
      },
      events: response.events,
    },
  };
};

export const useEventQuery = (queryOptions = {}) => useQuery(eventQuery, queryOptions, { fetchPolicy: 'cache-and-network' });

export const useSportsQuery = () => useQuery(allSportsQuery, {}, { fetchPolicy: 'cache-and-network' });

export const getMarketGroups = (variables = {}) => apolloClient
  .query({ query: marketGroupsBySport, variables })
  .then((response) => response.data?.marketGroups?.nodes || []);

export const getCompetitions = async ({ sportId = '', startDate = '' }) => {
  try {
    const response = await apolloClient.query({
      query: sportCompetitions,
      variables: {
        sportId,
        startDate,
      },
      context: {
        requestTrackerId: 'getCompetitions',
      },
      fetchPolicy: 'network-only',
    });
    return response?.data?.competitions?.nodes ?? [];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const useOperatorsFeaturesQuery = () => apolloClient
  .query({ query: operatorsFeaturesQuery, fetchPolicy: 'network-only' })
  .then((response) => response)
  .catch((err) => err);

export const getAllOperators = () => apolloClient
  .query(
    {
      query: allOperatorsQuery,
      context: {
        headers: {
          'x-admin-for': '*', // Special case where we need to define super admin parameter to fetch all of the operators
        },
      },
      fetchPolicy: 'network-only',
    },
  )
  .then((response) => response.data?.allOperators?.nodes || [])
  .catch((err) => err);

export const getTeamSquad = async ({ teamId, sportId }) => {
  let query;
  switch (sportId) {
  case FOOTBALL_ID:
    query = footballTeamByTeamIdQuery;
    break;
  case BASKETBALL_ID:
    query = basketballTeamByTeamIdQuery;
    break;
  case HOCKEY_ID:
    query = hockeyTeamByTeamIdQuery;
    break;
  case BASEBALL_ID:
    query = baseballTeamByTeamIdQuery;
    break;
  default:
    query = null;
  }

  if (!query) {
    console.log('Supported sports are Football and Basketball');
    return null;
  }
  try {
    const response = await apolloClient.query({
      query,
      variables: { teamId },
    });
    return response?.data?.team || [];
  } catch {
    return [];
  }
};

export const getMarketDisplayConfigurations = async ({ sportId = '' }) => {
  try {
    const response = await apolloClient.query({
      query: marketDisplayConfigurationsQuery,
      variables: {
        sportId,
      },
      fetchPolicy: 'network-only',
    });
    return {
      marketDisplayConfigurations: response?.data?.allMarketDisplayConfigurations?.nodes || [],
      allMarketParameters: response?.data?.allMarketParameters?.nodes || [],
    };
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const findUserConfiguration = () => apolloClient
  .query({
    query: userConfigurationQuery,
    variables: {},
    fetchPolicy: 'network-only',
    context: { requestTrackerId: 'findUserConfiguration' },
  })
  .then((response) => response.data?.userConfiguration?.nodes?.[0] || {});
