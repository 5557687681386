<template>
  <div class="event-card-competitor">
    <BaseballClock
      v-if="sportLabel === 'BASEBALL'"
      :side="side"
      :team-in-possession="teamInPossession"
    />
    <SportIcon
      v-else
      :class="[
        'event-card-competitor__possession-indicator',
        { 'event-card-competitor__possession-indicator--active': isInPossession },
      ]"
      :sport-label="sportLabel"
    />
    <div class="event-card-competitor__competitor-label">
      {{ name }}
    </div>
    <div
      v-if="showSuffix"
      class="event-card-competitor__suffix"
    >
      @
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import BaseballClock from '@/components/common/BaseballClock';
import SportIcon from '@/components/common/SportIcon';

export default {
  components: {
    BaseballClock,
    SportIcon,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    side: {
      type: String,
      required: true,
    },
    teamInPossession: {
      type: String,
      default: 'NEUTRAL',
    },
    isLive: {
      type: Boolean,
      default: false,
    },
    sportLabel: {
      type: String,
      default: '',
    },
    isUsaView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showPossessionIndicator = computed(() => props.sportLabel !== 'SOCCER' && props.isLive && props.sportLabel);
    const showStandardPossessionIndicator = computed(() => props.sportLabel !== 'BASEBALL' && showPossessionIndicator.value);
    const isInPossession = computed(() => props.side === props.teamInPossession && props.isLive);
    const showSuffix = computed(() => props.isUsaView && props.side === 'AWAY');

    return {
      showPossessionIndicator,
      showStandardPossessionIndicator,
      isInPossession,
      showSuffix,
    };
  },
};
</script>

<style lang="scss">
.event-card-competitor {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  margin-right: 0.25rem;

  .event-card-competitor__possession-indicator {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
    fill: #CDCDCD;

    &.event-card-competitor__possession-indicator--active {
      fill: #175FFF;
    }
  }

  .event-card-competitor__competitor-label {
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .event-card-competitor__suffix {
    color: #A9A9A9;
    margin-left: 0.25rem;
  }
}
</style>
