import routes from '@/../config/env';
import { v4 as uuidv4 } from 'uuid';
import { map, findIndex } from 'lodash';
import http from './http-client';

const { sgp } = routes;

export const getBetslipValues = (betslipData, withBlocking = false) => {
  const uuid = uuidv4();
  const data = {
    id: uuid,
    eventId: betslipData[0]?.eventId,
    sgpParts: map(betslipData, (bet) => ({
      marketId: bet.marketId,
      selectionId: bet.selection.selectionId,
    })),
  };
  return http({
    method: 'post',
    url: `${sgp}/api/v1/sgp/calculate-price/by-ids${withBlocking ? '/with-blocking' : ''}`,
    data,
  });
};

export const calculateCashout = (betslipData, calculatorData) => {
  const { stake, price } = calculatorData;
  const priceDecimalIndex = findIndex(price, (char) => char === '.' || char === ',');
  const numberOfDecimals = priceDecimalIndex > -1 ? (price.length - 1 - priceDecimalIndex) : 0;
  let formattedPrice = price.replace('.', '');
  formattedPrice = formattedPrice.replace(',', '');
  const uuid = uuidv4();
  const data = {
    id: uuid,
    eventId: betslipData[0]?.eventId,
    sgpParts: map(betslipData, (bet) => ({
      marketId: bet.marketId,
      selectionId: bet.selection.selectionId,
    })),
    originalSgpPrice: {
      decimalValue: Number(formattedPrice),
      decimalPlaces: numberOfDecimals,
    },
    originalSgpStake: {
      amount: parseInt(stake * 100, 10),
      freeBetAmount: 0,
      currency: 'USD',
    },
  };
  return http({
    method: 'post',
    url: `${sgp}/api/v1/sgp/calculate-cashout/by-ids`,
    data,
  });
};
