import types from './mutation-types';

export default {
  [types.SET_INITIALIZED](state, initialized) {
    state.initialized = initialized;
  },
  [types.SET_SCROLL_POSITION](state, scrollPosition) {
    state.scrollPosition = scrollPosition;
  },

  [types.UPDATE_SPORTS](state, sports) {
    state.sports = sports;
  },
  [types.UPDATE_COMPETITIONS](state, competitions) {
    state.competitions = competitions;
  },
  [types.SET_COMPETITIONS_LOADING](state, loading) {
    state.competitionsLoading = loading;
  },
  [types.SET_EVENTS](state, events) {
    state.events = events;
  },
  [types.UPDATE_EVENTS](state, { events, eventsTotalCount }) {
    state.events = events;
    state.eventsTotalCount = eventsTotalCount;
  },
  [types.SET_EVENTS_PAGE](state, page) {
    state.eventsPage = page;
  },
  [types.SET_EVENTS_LOADING](state, loading) {
    state.eventsLoading = loading;
  },
  [types.SET_EVENTS_PAGE_LOADING](state, pageLoading) {
    state.eventsPageLoading = pageLoading;
  },
  [types.SET_EVENTS_FILTERS](state, filters) {
    state.eventsFilters = filters;
  },
  [types.SET_MARKETS_FILTERS](state, filters) {
    state.marketsFilters = filters;
  },
  [types.SET_EVENTS_PAGINATION](state, pagination) {
    state.eventsPagination = pagination;
  },

  [types.CONNECTION_OPENED](state, connection) {
    state.connection = connection;
  },
  [types.CONNECTION_CLOSED](state) {
    state.connection = null;
  },
  [types.SET_LAZY_SUBSCRIBE](state, eventId) {
    state.lazySubscribeEventId = eventId;
  },
  [types.EVENT_UPDATED](state, event) {
    state.socketUpdatedEvent = event;
  },
  [types.UPDATE_ODD_FORMAT](state, oddFormat) {
    state.selectedOddFormat = oddFormat;
  },
  [types.STORE_USER_TOKEN](state, token) {
    state.userToken = token;
  },
  [types.STORE_USER_NAME](state, username) {
    state.username = username;
  },
  [types.STORE_USER_OPERATOR](state, operator) {
    state.operator = operator;
  },
  [types.UPDATE_LOGIN_ERROR_MESSAGE](state, message) {
    state.loginErrorMessage = message;
  },
  [types.UPDATE_CHANGE_PASSWORD_ATTRIBUTES](state, payload) {
    state.changePasswordAttributes = payload;
  },

  [types.SELECT_SPORT](state, sportId) {
    state.selectedSportId = sportId;
  },
  [types.SELECT_COMPETITION](state, competitionId) {
    state.selectedCompetitionId = competitionId;
  },
  [types.SELECT_DATE_RANGE](state, dateRange) {
    state.selectedDateRange = dateRange;
  },

  [types.SET_EVENT](state, event) {
    state.event = event;
  },
  [types.SET_EVENT_MARKETS](state, markets) {
    state.eventMarkets = markets;
  },
  [types.UPDATE_SINGLE_EVENT_MARKET](state, { deleteMarket, market, marketId }) {
    if (deleteMarket) {
      delete state.eventMarkets[marketId];
      return;
    }
    state.eventMarkets[market.marketId] = market;
  },

  [types.SET_EVENT_DISPLAY_MARKETS](state, markets) {
    state.eventDisplayMarkets = markets;
  },
  [types.SET_ALL_MARKET_PARAMETERS](state, parameters) {
    state.allMarketParameters = parameters;
  },
  [types.SET_EVENT_LOADING](state, eventLoading) {
    state.eventLoading = eventLoading;
  },
  [types.SET_EVENT_LOADING](state, eventLoading) {
    state.eventLoading = eventLoading;
  },
  [types.SELECT_MARKET_ID](state, marketId) {
    state.selectedMarketId = marketId;
  },
  [types.TOGGLE_MOB_SPORTS_ODDS_FILTER](state) {
    state.mobSportsOddsFilterToggled = !state.mobSportsOddsFilterToggled;
  },
  [types.TOGGLE_MOB_EVENTS_FILTER](state) {
    state.mobEventsFilterToggled = !state.mobEventsFilterToggled;
  },

  [types.SET_SEARCH_TOGGLED](state, toggled) {
    state.search.toggled = toggled;
  },
  [types.SET_SEARCH_QUERY](state, query) {
    state.search.query = query;
  },
  [types.SET_SEARCH_LOADING](state, loading) {
    state.search.loading = loading;
  },
  [types.SET_SEARCH_RECENT_RESULTS](state, recentResults) {
    state.search.recentResults = recentResults;
  },
  [types.SET_SEARCH_COMPETITOR_RESULTS](state, competitorResults) {
    state.search.competitorResults = competitorResults;
  },
  [types.SET_SEARCH_EVENT_RESULTS](state, eventResults) {
    state.search.eventResults = eventResults;
  },
  [types.SET_SEARCH_SELECTION](state, selection) {
    state.search.selection = selection;
  },
  [types.SET_MARKET_GROUPS_BY_SPORT](state, marketGroups) {
    state.marketGroupsBySport = marketGroups;
  },
  [types.SET_SELECTED_MARKET_GROUP](state, marketGroup) {
    state.selectedMarketGroup = marketGroup;
  },
  [types.SET_LATEST_LOADED_MARKET_GROUP_SPORT](state, sportId) {
    state.latestLoadedMarketGroupSport = sportId;
  },
  [types.SET_OPERATORS](state, operators) {
    state.operators = operators;
  },
  [types.SET_OPERATORS_LIST](state, data) {
    state.operatorsList = data;
  },
  [types.UPDATE_CASHOUT_AMOUNT](state, amount) {
    state.cashoutAmount = amount;
  },
  [types.UPDATE_BETSLIP_ERROR](state, error) {
    state.betslipError = error;
  },
  [types.UPDATE_BETSLIP](state, data) {
    if (!data) {
      state.betslip.bets = [];
      state.betslip.probability = '';
      state.betslip.totalPrice = '';
      state.betslip.availableSgpParts = [];
      return;
    }
    if (data.bets) {
      state.betslip.bets = data.bets;
    } else {
      state.betslip.probability = data.probability;
      state.betslip.totalPrice = data.totalPrice;
      state.betslip.availableSgpParts = data.availableSgpParts;
    }
  },
  [types.UPDATE_EVENT_TEAM_PLAYERS](state, { team, players }) {
    state.eventTeamPlayers[team] = players;
  },
  [types.UPDATE_SELECTED_EVENT_NAVIGATION_TAB](state, tab) {
    state.selectedEventNavigationTab = tab;
  },
};
