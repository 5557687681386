<template>
  <div :class="['price', { 'added-to-betslip': internalSource && addedToBetslip, 'internal': internalSource }]">
    <div
      v-if="isSuspended"
      class="suspended"
      @click="addBetToBetslip"
    >
      SUSPENDED
    </div>
    <div
      v-else
      class="full-width-height"
      @click="addBetToBetslip"
    >
      <div
        class="full-width-height"
      >
        <div
          v-if="sourceMarketSelection"
          class="full-width-height"
        >
          <EventMarketSelectionPriceValue
            class="event-markets-group-item-value full-width-height"
            :class="{'sgp-disabled': !isAvailableSgpPart}"
            :key="sourceMarketSelection.selectionType.selectionCode"
            :price-value="convertOdds(sourceMarketSelection.price)"
            :changed-price="sourceMarketSelection.price.changedPrice || ''"
            :display-status="sourceMarketSelection.displayStatus"
          />
        </div>

        <div v-else>
          -
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { find, map, includes } from 'lodash';
import OddsConverter from '@/services/helpers/odds-converter';
import EventMarketSelectionPriceValue from './EventMarketSelectionPriceValue';

export default {
  components: {
    EventMarketSelectionPriceValue,
  },
  props: {
    market: {
      type: Object,
      required: true,
    },
    betslipMarketName: {
      type: String,
      required: true,
    },
    source: {
      type: String,
      required: false,
      default: () => '',
    },
    selection: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    eventId: {
      type: String,
      default: '',
    },
    selectionName: {
      type: [Object, String],
      default: null,
    },
    hasSgp: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();

    const internalSource = computed(() => props.source === 'INTERNAL_AGGREGATION' || props.source === 'DPS');

    const selectedOddFormat = computed(() => store.getters.selectedOddFormat);
    const convertOdds = (price) => OddsConverter.getMappedOddValue(price, selectedOddFormat.value.name);

    const checkMarketParamsEquality = (firstMarketParam, secondMarketParam) => (
      firstMarketParam.LINE === secondMarketParam.LINE
      && firstMarketParam.PERIOD === secondMarketParam.PERIOD
      && firstMarketParam.HALF === secondMarketParam.HALF
      && firstMarketParam.TEAM === secondMarketParam.TEAM
      && firstMarketParam.RACE_TO === secondMarketParam.RACE_TO
      && firstMarketParam.BIN_MINUTE_START === secondMarketParam.BIN_MINUTE_START
      && firstMarketParam.BIN_MINUTE_END === secondMarketParam.BIN_MINUTE_END
      && firstMarketParam.PLAY === secondMarketParam.PLAY
      && firstMarketParam.PUNT === secondMarketParam.PUNT
      && firstMarketParam.FIELD_GOAL === secondMarketParam.FIELD_GOAL
      && firstMarketParam.SELECTIONS === secondMarketParam.SELECTIONS
      && firstMarketParam.PLAYER_INDEX === secondMarketParam.PLAYER_INDEX
      && firstMarketParam.RESULT_AFTER_X === secondMarketParam.RESULT_AFTER_X
      && firstMarketParam.PITCH_INDEX === secondMarketParam.PITCH_INDEX
      && firstMarketParam.PLATE_APPEARANCE === secondMarketParam.PLATE_APPEARANCE
      && firstMarketParam.BATTER_PLAYER_INDEX === secondMarketParam.BATTER_PLAYER_INDEX
      && firstMarketParam.PITCHER_PLAYER_INDEX === secondMarketParam.PITCHER_PLAYER_INDEX
      && firstMarketParam.IS_TOP_INNING === secondMarketParam.IS_TOP_INNING
      && firstMarketParam.TEAM_SCORE_AT_CREATION === secondMarketParam.TEAM_SCORE_AT_CREATION
      && firstMarketParam.HOME_SCORE_AT_CREATION === secondMarketParam.HOME_SCORE_AT_CREATION
      && firstMarketParam.AWAY_SCORE_AT_CREATION === secondMarketParam.AWAY_SCORE_AT_CREATION
      && firstMarketParam.MILESTONE_YARDS === secondMarketParam.MILESTONE_YARDS
    );

    const sourceMarketSelection = computed(() => {
      const foundSelection = find(props.market.selections,
        (selection) => selection.selectionType.selectionCode === props.selection.selectionType.selectionCode
        && selection.selectionType.params.HALF_X === props.selection.selectionType.params.HALF_X
        && selection.selectionType.params.PERIOD_X === props.selection.selectionType.params.PERIOD_X
        && selection.selectionType.params.LOWER_BOUND === props.selection.selectionType.params.LOWER_BOUND
        && selection.selectionType.params.TEAM === props.selection.selectionType.params.TEAM
        && selection.selectionType.params.UPPER_BOUND === props.selection.selectionType.params.UPPER_BOUND
        && selection.selectionType.params.CORRECT_SCORE === props.selection.selectionType.params.CORRECT_SCORE
        && selection.selectionType.params.CORRECT_SCORE_HOME === props.selection.selectionType.params.CORRECT_SCORE_HOME
        && selection.selectionType.params.CORRECT_SCORE_AWAY === props.selection.selectionType.params.CORRECT_SCORE_AWAY
        && selection.selectionType.params.MARGIN_AND_ABOVE === props.selection.selectionType.params.MARGIN_AND_ABOVE
        && selection.selectionType.params.MARGIN_EXACT === props.selection.selectionType.params.MARGIN_EXACT
        && selection.selectionType.params.MARGIN === props.selection.selectionType.params.MARGIN
        && selection.selectionType.params.GOALS_X === props.selection.selectionType.params.GOALS_X);

      return foundSelection;
    });

    const isSuspended = computed(() => props.market && sourceMarketSelection.value
      && (props.market.offeringStatus !== 'ACTIVE' || sourceMarketSelection.value.offeringStatus !== 'ACTIVE'));

    const betslipBets = computed(() => store.getters.betslip.bets);
    const availableSgpParts = computed(() => map(store.getters.betslip?.availableSgpParts, (part) => part.selectionId));

    const isAvailableSgpPart = computed(() => {
      const selectionAdded = find(betslipBets.value, (bet) => bet.selection?.selectionId === sourceMarketSelection.value?.selectionId);
      if (!route.query.sgpBlocking || betslipBets.value.length < 2 || selectionAdded) return true;
      if (includes(availableSgpParts.value, sourceMarketSelection.value?.selectionId)) return true;
      return false;
    });

    const hasSgpBetslip = computed(() => store.getters.betslipEnabled && props.hasSgp);

    const addedToBetslip = computed(() => internalSource.value && !!find(betslipBets.value,
      (bet) => sourceMarketSelection.value?.selectionId === bet.selection?.selectionId));

    const addBetToBetslip = () => {
      if (!internalSource.value || !hasSgpBetslip.value || !isAvailableSgpPart.value) return;
      if (find(betslipBets.value,
        (betslipBet) => (betslipBet.market.marketType.marketCode === props.market.marketType.marketCode
        && checkMarketParamsEquality(betslipBet.market.marketType.params, props.market.marketType.params)
        && betslipBet.selection.selectionId !== sourceMarketSelection.value?.selectionId))) return;
      const bet = {
        eventId: props.eventId,
        marketId: props.market?.marketId,
        market: props.market,
        marketName: props.betslipMarketName,
        selection: sourceMarketSelection.value,
        selectionName: props.selectionName?.twoPartLabel
          ? `${props.selectionName.leftSide}/${props.selectionName.rightSide}`
          : props.selectionName,
      };
      store.dispatch('updateBetslip', { bet });
    };

    return {
      sourceMarketSelection,
      convertOdds,
      isSuspended,
      addBetToBetslip,
      addedToBetslip,
      internalSource,
      isAvailableSgpPart,
    };
  },
};
</script>

<style lang="scss">
  .full-width-height {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .suspended {
    color: #99B1B1;
  }
</style>
