import {
  orderBy, sortBy, filter, isArray,
  find, isNil, map, startCase, assign,
  toLower, includes,
} from 'lodash';

const MARKET_TYPES = {
  RESULT: 'RESULT',
  GOAL_OVER_UNDER: 'GOAL_OVER_UNDER',
  HALF_RESULT: 'HALF_RESULT',
  HALF_TIME_FULL_TIME_EXC_OT: 'HALF_TIME_FULL_TIME_EXC_OT',
  HALF_TIME_FULL_TIME: 'HALF_TIME_FULL_TIME',
  POINT_HANDICAP_INC_OT: 'POINT_HANDICAP_INC_OT',
  HALF_POINT_HANDICAP: 'HALF_POINT_HANDICAP',
  POINT_HANDICAP: 'POINT_HANDICAP',
  GOAL_HANDICAP: 'GOAL_HANDICAP',
  HALF_POINT_OVER_UNDER: 'HALF_POINT_OVER_UNDER',
  POINT_OVER_UNDER_INC_OT: 'POINT_OVER_UNDER_INC_OT',
  TEAM_POINT_OVER_UNDER_INC_OT: 'TEAM_POINT_OVER_UNDER_INC_OT',
  NEXT_POINT: 'NEXT_POINT',
  PERIOD_POINT_OVER_UNDER: 'PERIOD_POINT_OVER_UNDER',
  PERIOD_POINT_HANDICAP: 'PERIOD_POINT_HANDICAP',
  PERIOD_GOAL_OVER_UNDER: 'PERIOD_GOAL_OVER_UNDER',
  PERIOD_GOAL_HANDICAP: 'PERIOD_GOAL_HANDICAP',
  PERIOD_RESULT: 'PERIOD_RESULT',
  POINT_OVER_UNDER: 'POINT_OVER_UNDER',
  TEAM_HALF_POINT_OVER_UNDER: 'TEAM_HALF_POINT_OVER_UNDER',
  TEAM_PERIOD_POINT_OVER_UNDER: 'TEAM_PERIOD_POINT_OVER_UNDER',
  TEAM_PERIOD_GOAL_OVER_UNDER: 'TEAM_PERIOD_GOAL_OVER_UNDER',
  TEAM_POINT_OVER_UNDER: 'TEAM_POINT_OVER_UNDER',
  TEAM_GOAL_OVER_UNDER: 'TEAM_GOAL_OVER_UNDER',
  RESULT_EXC_OT: 'RESULT_EXC_OT',
  MATCH_RESULT_AND_RUNS_OVER_UNDER: 'MATCH_RESULT_AND_RUNS_OVER_UNDER',

  POINT_MARGIN: 'POINT_MARGIN',
  HIGHEST_SCORING_HALF: 'HIGHEST_SCORING_HALF',
  HIGHEST_SCORING_PERIOD: 'HIGHEST_SCORING_PERIOD',
  POINT_RACE_TO_X: 'POINT_RACE_TO_X',
  HALF_POINT_RACE_TO_X: 'HALF_POINT_RACE_TO_X',
  PERIOD_POINT_RACE_TO_X: 'PERIOD_POINT_RACE_TO_X',
  HALF_POINT_ODD_EVEN: 'HALF_POINT_ODD_EVEN',
  POINT_ODD_EVEN_INC_OT: 'POINT_ODD_EVEN_INC_OT',
  PERIOD_POINT_ODD_EVEN: 'PERIOD_POINT_ODD_EVEN',
  POINT_ODD_EVEN: 'POINT_ODD_EVEN',
  TEAM_HALF_POINT_ODD_EVEN: 'TEAM_HALF_POINT_ODD_EVEN',
  TEAM_PERIOD_POINT_ODD_EVEN: 'TEAM_PERIOD_POINT_ODD_EVEN',
  TEAM_POINT_ODD_EVEN: 'TEAM_POINT_ODD_EVEN',
  TO_GO_TO_OVERTIME: 'TO_GO_TO_OVERTIME',
  POINT_ASIAN_HANDICAP: 'POINT_ASIAN_HANDICAP',
  PLAYER_NEXT_TOUCHDOWN_SCORER: 'PLAYER_NEXT_TOUCHDOWN_SCORER',
  PLAYER_ANYTIME_TOUCHDOWN_SCORER: 'PLAYER_ANYTIME_TOUCHDOWN_SCORER',
  PLAYER_LAST_TOUCHDOWN_SCORER: 'PLAYER_LAST_TOUCHDOWN_SCORER',
  PLAYER_PASSING_ATTEMPTS_OVER_UNDER: 'PLAYER_PASSING_ATTEMPTS_OVER_UNDER',
  PLAYER_PASSING_COMPLETIONS_OVER_UNDER: 'PLAYER_PASSING_COMPLETIONS_OVER_UNDER',
  PLAYER_PASSING_YARDS_OVER_UNDER: 'PLAYER_PASSING_YARDS_OVER_UNDER',
  PLAYER_RECEIVING_YARDS_OVER_UNDER: 'PLAYER_RECEIVING_YARDS_OVER_UNDER',
  PLAYER_RECEPTIONS_OVER_UNDER: 'PLAYER_RECEPTIONS_OVER_UNDER',
  PLAYER_RUSHING_YARDS_OVER_UNDER: 'PLAYER_RUSHING_YARDS_OVER_UNDER',
  PLAYER_TOUCHDOWNS_OVER_UNDER: 'PLAYER_TOUCHDOWNS_OVER_UNDER',

  HALF_CORRECT_SCORE: 'HALF_CORRECT_SCORE',
  HANDICAP_AND_OVER_UNDER: 'HANDICAP_AND_OVER_UNDER',
  MATCH_RESULT_AND_POINTS_OVER_UNDER: 'MATCH_RESULT_AND_POINTS_OVER_UNDER',
  NEXT_BASKET_EXACT: 'NEXT_BASKET_EXACT',
  NEXT_BASKET_TYPE: 'NEXT_BASKET_TYPE',
  NEXT_TWO_POINTER: 'NEXT_TWO_POINTER',
  NEXT_THREE_POINTER: 'NEXT_THREE_POINTER',
  PLAYER_POINT_OVER_UNDER: 'PLAYER_POINT_OVER_UNDER',
  PLAYER_POINT_BANDS: 'PLAYER_POINT_BANDS',
  PLAYER_REBOUND_OVER_UNDER: 'PLAYER_REBOUND_OVER_UNDER',
  PLAYER_ASSIST_OVER_UNDER: 'PLAYER_ASSIST_OVER_UNDER',
  PLAYER_THREE_POINTER_OVER_UNDER: 'PLAYER_THREE_POINTER_OVER_UNDER',
  ANYTIME_GOALSCORER: 'ANYTIME_GOALSCORER',
  ANY_QUARTER_TO_BE_SCORELESS: 'ANY_QUARTER_TO_BE_SCORELESS',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE: 'BOTH_TEAMS_TO_SCORE_X_OR_MORE',
  CORRECT_SCORE: 'CORRECT_SCORE',
  CORRECT_SCORE_EXC_OT: 'CORRECT_SCORE_EXC_OT',
  DEFENSIVE_OR_SPECIAL_TOUCHDOWN: 'DEFENSIVE_OR_SPECIAL_TOUCHDOWN',
  DRIVE_CROSSES_YARD_LINE: 'DRIVE_CROSSES_YARD_LINE',
  DRIVE_OUTCOME: 'DRIVE_OUTCOME',
  DRIVE_OUTCOME_GROUPED: 'DRIVE_OUTCOME_GROUPED',
  EITHER_TEAM_GET_FIRST_DOWN_ON_FOURTH_DOWN_PLAY: 'EITHER_TEAM_GET_FIRST_DOWN_ON_FOURTH_DOWN_PLAY',
  EITHER_TEAM_TO_SCORE_FOUR_TIMES_IN_A_ROW: 'EITHER_TEAM_TO_SCORE_FOUR_TIMES_IN_A_ROW',
  EITHER_TEAM_TO_SCORE_THREE_TIMES_IN_A_ROW: 'EITHER_TEAM_TO_SCORE_THREE_TIMES_IN_A_ROW',
  EITHER_TEAM_WIN_BY_THREE_OR_SEVEN: 'EITHER_TEAM_WIN_BY_THREE_OR_SEVEN',
  FIELD_GOAL_OVER_UNDER: 'FIELD_GOAL_OVER_UNDER',
  FIRST_DOWN_OVER_UNDER: 'FIRST_DOWN_OVER_UNDER',
  FIRST_DOWN_RESULT: 'FIRST_DOWN_RESULT',
  FIRST_PITCHER_TOTAL_STRIKEOUTS: 'FIRST_PITCHER_TOTAL_STRIKEOUTS',
  FIRST_SCORE_IS_A_TOUCHDOWN: 'FIRST_SCORE_IS_A_TOUCHDOWN',
  FOURTH_QUARTER_LEAD_CHANGE: 'FOURTH_QUARTER_LEAD_CHANGE',
  GOAL_IN_TIME_BIN: 'GOAL_IN_TIME_BIN',
  GOAL_MARGIN: 'GOAL_MARGIN',
  GOAL_MARGIN_EXC_OT: 'GOAL_MARGIN_EXC_OT',
  GOAL_ODD_EVEN_EXC_OT: 'GOAL_ODD_EVEN_EXC_OT',
  GOAL_RACE_TO_X: 'GOAL_RACE_TO_X',
  HALF_TIME_FULL_TIME_TIES_LOSE: 'HALF_TIME_FULL_TIME_TIES_LOSE',
  LAST_GOAL: 'LAST_GOAL',
  LAST_GOALSCORER: 'LAST_GOALSCORER',
  LAST_SCORE_IS_A_TOUCHDOWN: 'LAST_SCORE_IS_A_TOUCHDOWN',
  LONGEST_DRIVE_YARDS_OVER_UNDER: 'LONGEST_DRIVE_YARDS_OVER_UNDER',
  LONGEST_FIELD_GOAL_YARDS_OVER_UNDER: 'LONGEST_FIELD_GOAL_YARDS_OVER_UNDER',
  NEXT_CONVERSION: 'NEXT_CONVERSION',
  NEXT_FIELD_GOAL_ATTEMPT_IS_SUCCESSFUL: 'NEXT_FIELD_GOAL_ATTEMPT_IS_SUCCESSFUL',
  NEXT_FIELD_GOAL_ATTEMPT_SUCCESSFUL: 'NEXT_FIELD_GOAL_ATTEMPT_SUCCESSFUL',
  NEXT_FIELD_GOAL_MADE: 'NEXT_FIELD_GOAL_MADE',
  NEXT_GOAL: 'NEXT_GOAL',
  NEXT_GOALSCORER: 'NEXT_GOALSCORER',
  NEXT_KICKOFF_TOUCHBACK: 'NEXT_KICKOFF_TOUCHBACK',
  NEXT_PLAY_IS_A_FIRST_DOWN: 'NEXT_PLAY_IS_A_FIRST_DOWN',
  NEXT_PLAY_TOUCHDOWN: 'NEXT_PLAY_TOUCHDOWN',
  NEXT_PLAY_TYPE: 'NEXT_PLAY_TYPE',
  NEXT_POSSESSION_OUTCOME: 'NEXT_POSSESSION_OUTCOME',
  NEXT_POSSESSION_OUTCOME_EXACT: 'NEXT_POSSESSION_OUTCOME_EXACT',
  NEXT_PUNT_FAIR_CATCH: 'NEXT_PUNT_FAIR_CATCH',
  PERIOD_CORRECT_SCORE: 'PERIOD_CORRECT_SCORE',
  PERIOD_GOAL_OVER_UNDER_FIRST_X_MINUTES: 'PERIOD_GOAL_OVER_UNDER_FIRST_X_MINUTES',
  PERIOD_PLAYER_POINT_BANDS: 'PERIOD_PLAYER_POINT_BANDS',
  PERIOD_PLAYER_POINT_OVER_UNDER: 'PERIOD_PLAYER_POINT_OVER_UNDER',
  PERIOD_PLAYER_THREE_POINTER_OVER_UNDER: 'PERIOD_PLAYER_THREE_POINTER_OVER_UNDER',
  PERIOD_POINT_HANDICAP_EXC_OT: 'PERIOD_POINT_HANDICAP_EXC_OT',
  PERIOD_POINT_OVER_UNDER_EXC_OT: 'PERIOD_POINT_OVER_UNDER_EXC_OT',
  PERIOD_RESULT_EXC_OT: 'PERIOD_RESULT_EXC_OT',
  PERIOD_RESULT_FULL_TIME: 'PERIOD_RESULT_FULL_TIME',
  PERIOD_RUN_HANDICAP: 'PERIOD_RUN_HANDICAP',
  PERIOD_RUN_OVER_UNDER: 'PERIOD_RUN_OVER_UNDER',
  PITCH_OUTCOME: 'PITCH_OUTCOME',
  PITCH_SPEED_BANDED: 'PITCH_SPEED_BANDED',
  PITCH_SPEED_OVER_UNDER: 'PITCH_SPEED_OVER_UNDER',
  PLATE_APPEARANCE_PITCHES_OVER_UNDER: 'PLATE_APPEARANCE_PITCHES_OVER_UNDER',
  PLATE_APPEARANCE_RESULT_EXACT: 'PLATE_APPEARANCE_RESULT_EXACT',
  PLATE_APPEARANCE_RESULT_GROUPED: 'PLATE_APPEARANCE_RESULT_GROUPED',
  PLATE_APPEARANCE_RESULT_ON_BASE: 'PLATE_APPEARANCE_RESULT_ON_BASE',
  PLAYER_BASES_OVER_UNDER: 'PLAYER_BASES_OVER_UNDER',
  PLAYER_HOME_RUN: 'PLAYER_HOME_RUN',
  PLAYER_ON_BASE: 'PLAYER_ON_BASE',
  PLAYER_ON_BASE_TWO_PLUS: 'PLAYER_ON_BASE_TWO_PLUS',
  PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER: 'PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER',
  PLAYER_RBI: 'PLAYER_RBI',
  PLAYER_RUN_OVER_UNDER: 'PLAYER_RUN_OVER_UNDER',
  PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER: 'PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER',
  PLAYER_SHOTS_OVER_UNDER: 'PLAYER_SHOTS_OVER_UNDER',
  PLAYER_STOLEN_BASE: 'PLAYER_STOLEN_BASE',
  PLAYER_STRIKEOUT_OVER_UNDER: 'PLAYER_STRIKEOUT_OVER_UNDER',
  PLAYER_TOUCHDOWN_X_OR_MORE: 'PLAYER_TOUCHDOWN_X_OR_MORE',
  PUNT_OVER_UNDER: 'PUNT_OVER_UNDER',
  PUNT_YARDS_OVER_UNDER: 'PUNT_YARDS_OVER_UNDER',
  RESULT_AFTER: 'RESULT_AFTER',
  TWO_WAY_RESULT_AFTER: 'TWO_WAY_RESULT_AFTER',
  RUN_HANDICAP: 'RUN_HANDICAP',
  RUN_HANDICAP_AFTER: 'RUN_HANDICAP_AFTER',
  RUN_MARGIN: 'RUN_MARGIN',
  RUN_OVER_UNDER: 'RUN_OVER_UNDER',
  RUN_OVER_UNDER_AFTER: 'RUN_OVER_UNDER_AFTER',
  SAFETY_SCORED: 'SAFETY_SCORED',
  SECOND_HALF_LEAD_CHANGE: 'SECOND_HALF_LEAD_CHANGE',
  STRIKEOUT_OVER_UNDER: 'STRIKEOUT_OVER_UNDER',
  SUCCESSFUL_TWO_POINT_CONVERSION: 'SUCCESSFUL_TWO_POINT_CONVERSION',
  TEAM_FIRST_TO_SCORE: 'TEAM_FIRST_TO_SCORE',
  TEAM_LAST_TO_SCORE: 'TEAM_LAST_TO_SCORE',
  TEAM_NEXT_BASKET_TYPE: 'TEAM_NEXT_BASKET_TYPE',
  TEAM_TO_SCORE_NEXT_BASKET: 'TEAM_TO_SCORE_NEXT_BASKET',
  TEAM_NEXT_TO_SCORE: 'TEAM_NEXT_TO_SCORE',
  TEAM_OVER_UNDER: 'TEAM_OVER_UNDER',
  TEAM_PERIOD_BALLS_IN_PLAY: 'TEAM_PERIOD_BALLS_IN_PLAY',
  TEAM_PERIOD_BALLS_IN_PLAY_OVER_UNDER: 'TEAM_PERIOD_BALLS_IN_PLAY_OVER_UNDER',
  TEAM_PERIOD_BATTERS_TO_PLATE: 'TEAM_PERIOD_BATTERS_TO_PLATE',
  TEAM_PERIOD_BATTERS_TO_PLATE_OVER_UNDER: 'TEAM_PERIOD_BATTERS_TO_PLATE_OVER_UNDER',
  TEAM_PERIOD_HITS: 'TEAM_PERIOD_HITS',
  TEAM_PERIOD_HITS_OVER_UNDER: 'TEAM_PERIOD_HITS_OVER_UNDER',
  TEAM_PERIOD_HOME_RUN: 'TEAM_PERIOD_HOME_RUN',
  TEAM_PERIOD_PITCHES_BANDED: 'TEAM_PERIOD_PITCHES_BANDED',
  TEAM_PERIOD_PITCHES_OVER_UNDER: 'TEAM_PERIOD_PITCHES_OVER_UNDER',
  TEAM_PERIOD_POINT_OVER_UNDER_EXC_OT: 'TEAM_PERIOD_POINT_OVER_UNDER_EXC_OT',
  TEAM_PERIOD_RUN_SCORED: 'TEAM_PERIOD_RUN_SCORED',
  TEAM_PERIOD_RUN_OVER_UNDER: 'TEAM_PERIOD_RUN_OVER_UNDER',
  TEAM_PERIOD_STRIKEOUT_OVER_UNDER: 'TEAM_PERIOD_STRIKEOUT_OVER_UNDER',
  TEAM_PERIOD_THREE_BATTERS_REACH_BASE: 'TEAM_PERIOD_THREE_BATTERS_REACH_BASE',
  TEAM_PERIOD_THREE_BATTERS_STRIKEOUT: 'TEAM_PERIOD_THREE_BATTERS_STRIKEOUT',
  TEAM_PERIOD_TO_SCORE: 'TEAM_PERIOD_TO_SCORE',
  TEAM_RUN_OVER_UNDER: 'TEAM_RUN_OVER_UNDER',
  TEAM_RUN_OVER_UNDER_AFTER: 'TEAM_RUN_OVER_UNDER_AFTER',
  TEAM_SCORE_IN_EACH_PERIOD: 'TEAM_SCORE_IN_EACH_PERIOD',
  TEAM_STACK_ATTACK_OVER_UNDER: 'TEAM_STACK_ATTACK_OVER_UNDER',
  TEAM_STRIKEOUT_OVER_UNDER: 'TEAM_STRIKEOUT_OVER_UNDER',
  TEAM_TO_SCORE_FIRST_WINS_MATCH: 'TEAM_TO_SCORE_FIRST_WINS_MATCH',
  TEAM_TO_SCORE_IN_OWN_DRIVE: 'TEAM_TO_SCORE_IN_OWN_DRIVE',
  TEAM_TO_SCORE_LAST_WINS_MATCH: 'TEAM_TO_SCORE_LAST_WINS_MATCH',
  TIE_AFTER_NIL_NIL: 'TIE_AFTER_NIL_NIL',
  TIME_OF_FIRST_SCORE: 'TIME_OF_FIRST_SCORE',
  TOUCHDOWN_IN_TIME_BIN: 'TOUCHDOWN_IN_TIME_BIN',
  TOUCHDOWN_OVER_UNDER: 'TOUCHDOWN_OVER_UNDER',
  TO_GO_THREE_AND_OUT: 'TO_GO_THREE_AND_OUT',
  TURNOVER_OVER_UNDER: 'TURNOVER_OVER_UNDER',
  WIN_TO_NIL: 'WIN_TO_NIL',
  LAST_POINT: 'LAST_POINT',
  FIRST_SCORER_WINS_MATCH: 'FIRST_SCORER_WINS_MATCH',
  FIELD_GOAL_ODD_EVEN: 'FIELD_GOAL_ODD_EVEN',
  THREE_WAY_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_FIELD_GOAL_RESULT: 'THREE_WAY_FIELD_GOAL_RESULT',
  TWO_WAY_FIELD_GOAL_RESULT: 'TWO_WAY_FIELD_GOAL_RESULT',
  HALF_FIELD_GOAL_ODD_EVEN: 'HALF_FIELD_GOAL_ODD_EVEN',
  HALF_FIELD_GOAL_OVER_UNDER: 'HALF_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_HALF_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_HALF_FIELD_GOAL_OVER_UNDER',
  HALF_FIELD_GOAL_RESULT: 'HALF_FIELD_GOAL_RESULT',
  THREE_WAY_HALF_FIELD_GOAL_RESULT: 'THREE_WAY_HALF_FIELD_GOAL_RESULT',
  PERIOD_FIELD_GOAL_ODD_EVEN: 'PERIOD_FIELD_GOAL_ODD_EVEN',
  PERIOD_FIELD_GOAL_OVER_UNDER: 'PERIOD_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_PERIOD_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_PERIOD_FIELD_GOAL_OVER_UNDER',
  PERIOD_FIELD_GOAL_RESULT: 'PERIOD_FIELD_GOAL_RESULT',
  THREE_WAY_PERIOD_FIELD_GOAL_RESULT: 'THREE_WAY_PERIOD_FIELD_GOAL_RESULT',
  TEAM_HALF_FIELD_GOAL_OVER_UNDER: 'TEAM_HALF_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_TEAM_HALF_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_TEAM_HALF_FIELD_GOAL_OVER_UNDER',
  TEAM_PERIOD_FIELD_GOAL_OVER_UNDER: 'TEAM_PERIOD_FIELD_GOAL_OVER_UNDER',
  THREE_WAY_TEAM_PERIOD_FIELD_GOAL_OVER_UNDER: 'THREE_WAY_TEAM_PERIOD_FIELD_GOAL_OVER_UNDER',
  POINT_HANDICAP_EXC_OT: 'POINT_HANDICAP_EXC_OT',
  POINT_OVER_UNDER_EXC_OT: 'POINT_OVER_UNDER_EXC_OT',
  THREE_WAY_POINT_OVER_UNDER_EXC_OT: 'THREE_WAY_POINT_OVER_UNDER_EXC_OT',
  THREE_WAY_TEAM_POINT_OVER_UNDER: 'THREE_WAY_TEAM_POINT_OVER_UNDER',
  HALF_POINT_HANDICAP_EXC_OT: 'HALF_POINT_HANDICAP_EXC_OT',
  THREE_WAY_HALF_POINT_HANDICAP_EXC_OT: 'THREE_WAY_HALF_POINT_HANDICAP_EXC_OT',
  THREE_WAY_PERIOD_POINT_HANDICAP_EXC_OT: 'THREE_WAY_PERIOD_POINT_HANDICAP_EXC_OT',
  HALF_POINT_OVER_UNDER_EXC_OT: 'HALF_POINT_OVER_UNDER_EXC_OT',
  THREE_WAY_HALF_POINT_OVER_UNDER_EXC_OT: 'THREE_WAY_HALF_POINT_OVER_UNDER_EXC_OT',
  HALF_RESULT_EXC_OT: 'HALF_RESULT_EXC_OT',
  TWO_WAY_HALF_RESULT_EXC_OT: 'TWO_WAY_HALF_RESULT_EXC_OT',
  THREE_WAY_PERIOD_POINT_OVER_UNDER_EXC_OT: 'THREE_WAY_PERIOD_POINT_OVER_UNDER_EXC_OT',
  TWO_WAY_PERIOD_RESULT_EXC_OT: 'TWO_WAY_PERIOD_RESULT_EXC_OT',
  THREE_WAY_TEAM_HALF_POINT_OVER_UNDER: 'THREE_WAY_TEAM_HALF_POINT_OVER_UNDER',
  TEAM_HALF_TOUCHDOWN_OVER_UNDER: 'TEAM_HALF_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TEAM_HALF_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_TEAM_HALF_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TEAM_PERIOD_POINT_OVER_UNDER: 'THREE_WAY_TEAM_PERIOD_POINT_OVER_UNDER',
  TEAM_PERIOD_TOUCHDOWN_OVER_UNDER: 'TEAM_PERIOD_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TEAM_PERIOD_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_TEAM_PERIOD_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_TOUCHDOWN_OVER_UNDER',
  TEAM_TOUCHDOWN_OVER_UNDER: 'TEAM_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_TEAM_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_TEAM_TOUCHDOWN_OVER_UNDER',
  PERIOD_TOUCHDOWN_OVER_UNDER: 'PERIOD_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_PERIOD_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_PERIOD_TOUCHDOWN_OVER_UNDER',
  HALF_TOUCHDOWN_OVER_UNDER: 'HALF_TOUCHDOWN_OVER_UNDER',
  THREE_WAY_HALF_TOUCHDOWN_OVER_UNDER: 'THREE_WAY_HALF_TOUCHDOWN_OVER_UNDER',
  HALF_TOUCHDOWN_ODD_EVEN: 'HALF_TOUCHDOWN_ODD_EVEN',
  HALF_TOUCHDOWN_RESULT: 'HALF_TOUCHDOWN_RESULT',
  THREE_WAY_HALF_TOUCHDOWN_RESULT: 'THREE_WAY_HALF_TOUCHDOWN_RESULT',
  PERIOD_TOUCHDOWN_ODD_EVEN: 'PERIOD_TOUCHDOWN_ODD_EVEN',
  PERIOD_TOUCHDOWN_RESULT: 'PERIOD_TOUCHDOWN_RESULT',
  THREE_WAY_TOUCHDOWN_RESULT: 'THREE_WAY_TOUCHDOWN_RESULT',
  TWO_WAY_TOUCHDOWN_RESULT: 'TWO_WAY_TOUCHDOWN_RESULT',
  TOUCHDOWN_HIGHEST_SCORING_HALF: 'TOUCHDOWN_HIGHEST_SCORING_HALF',
  TOUCHDOWN_HIGHEST_SCORING_PERIOD: 'TOUCHDOWN_HIGHEST_SCORING_PERIOD',
  TOUCHDOWN_ODD_EVEN: 'TOUCHDOWN_ODD_EVEN',
  MOST_POINTS_IN_SECOND_QUARTER: 'MOST_POINTS_IN_SECOND_QUARTER',

  BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_QUARTER: 'BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_QUARTER',
  POINTS_IN_EACH_QUARTER: 'POINTS_IN_EACH_QUARTER',
  FIRST_SCORING_PLAY_TYPE: 'FIRST_SCORING_PLAY_TYPE',
  TEAM_TO_LEAD_AFTER_EACH_QUARTER: 'TEAM_TO_LEAD_AFTER_EACH_QUARTER',
  TEAM_TO_SCORE_X_OR_MORE_POINTS: 'TEAM_TO_SCORE_X_OR_MORE_POINTS',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_POINTS: 'BOTH_TEAMS_TO_SCORE_X_OR_MORE_POINTS',
  PLAYER_TO_SCORE_FIRST_TOUCHDOWN_AND_TEAM_TO_WIN: 'PLAYER_TO_SCORE_FIRST_TOUCHDOWN_AND_TEAM_TO_WIN',
  PLAYER_TO_SCORE_TOUCHDOWN_AND_TEAM_TO_WIN: 'PLAYER_TO_SCORE_TOUCHDOWN_AND_TEAM_TO_WIN',
  LOWEST_SCORING_HALF: 'LOWEST_SCORING_HALF',
  LOWEST_SCORING_PERIOD: 'LOWEST_SCORING_PERIOD',
  BOTH_TEAMS_TO_SCORE_FIELD_GOAL: 'BOTH_TEAMS_TO_SCORE_FIELD_GOAL',
  BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS: 'BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS',
  FIELD_GOAL_IN_EACH_PERIOD: 'FIELD_GOAL_IN_EACH_PERIOD',
  TOUCHDOWN_IN_EACH_PERIOD: 'TOUCHDOWN_IN_EACH_PERIOD',
  FIRST_SECOND_HALF_SCORING_PLAY_TYPE: 'FIRST_SECOND_HALF_SCORING_PLAY_TYPE',
  TEAM_FIRST_SCORING_PLAY_TYPE: 'TEAM_FIRST_SCORING_PLAY_TYPE',
  FIRST_FIELD_GOAL_YARDAGE_OVER_UNDER: 'FIRST_FIELD_GOAL_YARDAGE_OVER_UNDER',
  FIRST_SCORE_YARDAGE_OVER_UNDER: 'FIRST_SCORE_YARDAGE_OVER_UNDER',
  FIRST_TOUCHDOWN_YARDAGE_OVER_UNDER: 'FIRST_TOUCHDOWN_YARDAGE_OVER_UNDER',
  TEAM_NEXT_DRIVE_OUTCOME: 'TEAM_NEXT_DRIVE_OUTCOME',
  TEAM_TO_SCORE_FIRST_FIELD_GOAL: 'TEAM_TO_SCORE_FIRST_FIELD_GOAL',
  TEAM_TO_SCORE_FIRST_TOUCHDOWN: 'TEAM_TO_SCORE_FIRST_TOUCHDOWN',
  BOTH_TEAMS_TO_SCORE_IN_X_PERIOD: 'BOTH_TEAMS_TO_SCORE_IN_X_PERIOD',
  BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_HALF: 'BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_HALF',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_FIELD_GOALS: 'BOTH_TEAMS_TO_SCORE_X_OR_MORE_FIELD_GOALS',
  BOTH_TEAMS_TO_SCORE_X_OR_MORE_TOUCHDOWNS: 'BOTH_TEAMS_TO_SCORE_X_OR_MORE_TOUCHDOWNS',
  LARGEST_LEAD_OF_GAME: 'LARGEST_LEAD_OF_GAME',
  LAST_SCORING_PLAY_TYPE: 'LAST_SCORING_PLAY_TYPE',
  HALF_TEAM_LAST_TO_SCORE: 'HALF_TEAM_LAST_TO_SCORE',
  LONGEST_TOUCHDOWN_SCORED: 'LONGEST_TOUCHDOWN_SCORED',
  PERIOD_OF_FIRST_FIELD_GOAL: 'PERIOD_OF_FIRST_FIELD_GOAL',
  PERIOD_OF_FIRST_TOUCHDOWN: 'PERIOD_OF_FIRST_TOUCHDOWN',
  TOUCHDOWN_IN_PERIOD: 'TOUCHDOWN_IN_PERIOD',
  TEAM_NUMBER_OF_HALVES_WON: 'TEAM_NUMBER_OF_HALVES_WON',
  TEAM_NUMBER_OF_PERIODS_WON: 'TEAM_NUMBER_OF_PERIODS_WON',
  TEAM_TO_SCORE_LONGEST_FIELD_GOAL: 'TEAM_TO_SCORE_LONGEST_FIELD_GOAL',
  TEAM_TO_SCORE_LONGEST_TOUCHDOWN: 'TEAM_TO_SCORE_LONGEST_TOUCHDOWN',
  POINT_TOTAL_BANDS: 'POINT_TOTAL_BANDS',
  NEXT_PLAY_OFFENSIVE_TOUCHDOWN: 'NEXT_PLAY_OFFENSIVE_TOUCHDOWN',
  POINT_MARGIN_BANDS: 'POINT_MARGIN_BANDS',
  FIELD_GOAL_RESULT: 'FIELD_GOAL_RESULT',
  TOUCHDOWN_RESULT: 'TOUCHDOWN_RESULT',
  RESULT_AND_OVER_UNDER: 'RESULT_AND_OVER_UNDER',
  TEAM_TO_SCORE_FIRST_LOSES_MATCH: 'TEAM_TO_SCORE_FIRST_LOSES_MATCH',

  // Micro markets added 15.09.2023.
  CURRENT_DRIVE_OUTCOME: 'CURRENT_DRIVE_OUTCOME',
  CURRENT_DRIVE_OUTCOME_GROUPED: 'CURRENT_DRIVE_OUTCOME_GROUPED',
  NEXT_DRIVE_OUTCOME: 'NEXT_DRIVE_OUTCOME',
  NEXT_DRIVE_OUTCOME_GROUPED: 'NEXT_DRIVE_OUTCOME_GROUPED',
  CURRENT_DRIVE_TEAM_TO_GO_THREE_AND_OUT: 'CURRENT_DRIVE_TEAM_TO_GO_THREE_AND_OUT',
  NEXT_DRIVE_TEAM_TO_GO_THREE_AND_OUT: 'NEXT_DRIVE_TEAM_TO_GO_THREE_AND_OUT',
  SITUATIONAL_EXTRA_POINT_CONVERSION_MADE: 'SITUATIONAL_EXTRA_POINT_CONVERSION_MADE',
  SITUATIONAL_FIELD_GOAL_ATTEMPT_MADE: 'SITUATIONAL_FIELD_GOAL_ATTEMPT_MADE',
  SITUATIONAL_TWO_POINTS_CONVERSION_MADE: 'SITUATIONAL_TWO_POINTS_CONVERSION_MADE',

  // not micro markets but added 15.09.2023. (discovered on supplement mode)
  PUNT_TOTAL_YARDS_OVER_UNDER: 'PUNT_TOTAL_YARDS_OVER_UNDER',
  NEXT_PLAY_IS_FIRST_DOWN: 'NEXT_PLAY_IS_FIRST_DOWN',
  PERIOD_TEAM_TO_SCORE_FIRST: 'PERIOD_TEAM_TO_SCORE_FIRST',
  SCORE_IN_LAST_TWO_MINUTES_OF_HALF: 'SCORE_IN_LAST_TWO_MINUTES_OF_HALF',
  BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS_ONE_LINE: 'BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS_ONE_LINE',

  MOST_POINTS_IN_PERIOD: 'MOST_POINTS_IN_PERIOD',

};

const MARKET_MAPPINGS = {
  // Soccer
  [MARKET_TYPES.RESULT]: 'Moneyline',
  [MARKET_TYPES.GOAL_OVER_UNDER]: 'Total',
  [MARKET_TYPES.HALF_RESULT]: '{x} Half Moneyline',

  // Basketball
  [MARKET_TYPES.POINT_RACE_TO_X]: 'Race To {x} Points',
  [MARKET_TYPES.HALF_POINT_RACE_TO_X]: '{x} Half Race To {y} Points',
  [MARKET_TYPES.PERIOD_POINT_RACE_TO_X]: '{x} Period Race To {y} Points',
  [MARKET_TYPES.POINT_HANDICAP_INC_OT]: 'Spread (incl. OT)',
  [MARKET_TYPES.POINT_MARGIN]: 'Winning Margin',
  [MARKET_TYPES.HALF_POINT_ODD_EVEN]: '{x} Half Odd / Even',
  [MARKET_TYPES.POINT_ODD_EVEN_INC_OT]: 'Total Points Odd / Even (incl. OT)',
  [MARKET_TYPES.POINT_OVER_UNDER_INC_OT]: 'Total (incl. OT)',
  [MARKET_TYPES.TEAM_POINT_OVER_UNDER_INC_OT]: '{x} Total',
  [MARKET_TYPES.HIGHEST_SCORING_HALF]: 'Highest Scoring Half',
  [MARKET_TYPES.HIGHEST_SCORING_PERIOD]: 'Highest Scoring Period',
  [MARKET_TYPES.NEXT_POINT]: '{x} Point',
  [MARKET_TYPES.PERIOD_POINT_ODD_EVEN]: '{x} Period Odd / Even Total',
  [MARKET_TYPES.PERIOD_RESULT]: '{x} Period Moneyline',
  [MARKET_TYPES.POINT_ODD_EVEN]: 'Total Points Odd / Even',
  [MARKET_TYPES.TEAM_HALF_POINT_ODD_EVEN]: '{x} Half {y} Odd / Even Total',
  [MARKET_TYPES.TEAM_HALF_POINT_OVER_UNDER]: '{x} Half {y} Total',
  [MARKET_TYPES.TEAM_PERIOD_POINT_ODD_EVEN]: '{x} Period {y} Odd / Even Total',
  [MARKET_TYPES.TEAM_PERIOD_POINT_OVER_UNDER]: '{x} Period {y} Total',
  [MARKET_TYPES.TEAM_PERIOD_GOAL_OVER_UNDER]: '{x} Period {y} Total',
  [MARKET_TYPES.TEAM_POINT_ODD_EVEN]: '{x} Total Points Odd / Even',
  [MARKET_TYPES.TEAM_POINT_OVER_UNDER]: '{x} Total',
  [MARKET_TYPES.TEAM_GOAL_OVER_UNDER]: '{x} Total',
  [MARKET_TYPES.TO_GO_TO_OVERTIME]: 'To Go To Overtime',
  [MARKET_TYPES.RESULT_EXC_OT]: 'Moneyline (excl. OT)',

  // Ultimate
  [MARKET_TYPES.POINT_ASIAN_HANDICAP]: 'Asian Handicap',
  [MARKET_TYPES.HALF_POINT_HANDICAP]: '{x} Half Spread',
  [MARKET_TYPES.POINT_HANDICAP]: 'Spread',
  [MARKET_TYPES.GOAL_HANDICAP]: 'Spread',
  [MARKET_TYPES.HALF_POINT_OVER_UNDER]: '{x} Half Total',
  [MARKET_TYPES.PERIOD_POINT_OVER_UNDER]: '{x} Period Total',
  [MARKET_TYPES.PERIOD_POINT_HANDICAP]: '{x} Period Spread',
  [MARKET_TYPES.PERIOD_GOAL_OVER_UNDER]: '{x} Period Total',
  [MARKET_TYPES.PERIOD_GOAL_HANDICAP]: '{x} Period Spread',
  [MARKET_TYPES.POINT_OVER_UNDER]: 'Total',
  [MARKET_TYPES.HALF_TIME_FULL_TIME_EXC_OT]: 'Half Time / Full Time (excl. OT)',
  [MARKET_TYPES.HALF_TIME_FULL_TIME]: 'Half Time / Full Time',

  // Baseball

  [MARKET_TYPES.MATCH_RESULT_AND_RUNS_OVER_UNDER]: 'Moneyline and Total',

  // Player props
  [MARKET_TYPES.PLAYER_LAST_TOUCHDOWN_SCORER]: '{y} ({x}) To Score Last Touchdown',
  [MARKET_TYPES.PLAYER_NEXT_TOUCHDOWN_SCORER]: '{y} ({x}) To Score {z} Touchdown',
  [MARKET_TYPES.PLAYER_ANYTIME_TOUCHDOWN_SCORER]: '{y} ({x}) To Score Any Touchdown',
  [MARKET_TYPES.PLAYER_PASSING_ATTEMPTS_OVER_UNDER]: '{y} ({x}) Total Passing Attempts',
  [MARKET_TYPES.PLAYER_PASSING_COMPLETIONS_OVER_UNDER]: '{y} ({x}) Total Pass Completions',
  [MARKET_TYPES.PLAYER_PASSING_YARDS_OVER_UNDER]: '{y} ({x}) Total Passing Yards',
  [MARKET_TYPES.PLAYER_RECEIVING_YARDS_OVER_UNDER]: '{y} ({x}) Total Receiving Yards',
  [MARKET_TYPES.PLAYER_RECEPTIONS_OVER_UNDER]: '{y} ({x}) Total Receptions',
  [MARKET_TYPES.PLAYER_RUSHING_YARDS_OVER_UNDER]: '{y} ({x}) Total Rushing Yards',
  [MARKET_TYPES.PLAYER_TOUCHDOWNS_OVER_UNDER]: '{y} ({x}) Touchdowns',

  [MARKET_TYPES.HALF_CORRECT_SCORE]: '{x} Half Correct Score',
  [MARKET_TYPES.HANDICAP_AND_OVER_UNDER]: 'Spread and Total',
  [MARKET_TYPES.MATCH_RESULT_AND_POINTS_OVER_UNDER]: 'Moneyline and Total',
  [MARKET_TYPES.NEXT_BASKET_EXACT]: 'Next Basket',
  [MARKET_TYPES.NEXT_BASKET_TYPE]: 'Next Basket Type',
  [MARKET_TYPES.NEXT_TWO_POINTER]: '{x} To Score Next 2 Pointer',
  [MARKET_TYPES.NEXT_THREE_POINTER]: '{x} To Score Next 3 Pointer',
  [MARKET_TYPES.PLAYER_POINT_OVER_UNDER]: '{y} ({x}) Total Points',
  [MARKET_TYPES.PLAYER_POINT_BANDS]: '{y} ({x}) Point Bands',
  [MARKET_TYPES.PLAYER_REBOUND_OVER_UNDER]: '{y} ({x}) Rebounds',
  [MARKET_TYPES.PLAYER_ASSIST_OVER_UNDER]: '{y} ({x}) Total Assists',
  [MARKET_TYPES.PLAYER_THREE_POINTER_OVER_UNDER]: '{y} ({x}) 3 Pointers',
  [MARKET_TYPES.ANYTIME_GOALSCORER]: '{y} ({x}) To Score',
  [MARKET_TYPES.ANY_QUARTER_TO_BE_SCORELESS]: 'Will Any Quarter Be Scoreless',
  [MARKET_TYPES.BOTH_TEAMS_TO_SCORE_X_OR_MORE]: 'Both Teams To Score X Or More',
  [MARKET_TYPES.CORRECT_SCORE]: 'Correct Score',
  [MARKET_TYPES.CORRECT_SCORE_EXC_OT]: 'Correct Score (excl. OT)',
  [MARKET_TYPES.DEFENSIVE_OR_SPECIAL_TOUCHDOWN]: 'Will There Be A Defensive Or Special Teams Touchdown',
  [MARKET_TYPES.DRIVE_CROSSES_YARD_LINE]: '{y} Drive for {x} Crosses {z} Yard Line',
  [MARKET_TYPES.DRIVE_OUTCOME]: 'Outcome of {y} Drive for {x}',
  [MARKET_TYPES.DRIVE_OUTCOME_GROUPED]: 'Grouped Outcome of {y} Drive for {x}',
  [MARKET_TYPES.EITHER_TEAM_GET_FIRST_DOWN_ON_FOURTH_DOWN_PLAY]: 'With Either Team Convert A 4th Down',
  [MARKET_TYPES.EITHER_TEAM_TO_SCORE_FOUR_TIMES_IN_A_ROW]: 'Will Either Team Score Four Times In A Row (Safeties Count)',
  [MARKET_TYPES.EITHER_TEAM_TO_SCORE_THREE_TIMES_IN_A_ROW]: 'Will Either Team Score Three Times In A Row (Safeties Count)',
  [MARKET_TYPES.EITHER_TEAM_WIN_BY_THREE_OR_SEVEN]: 'Either Team To Win By 3 or 7',
  [MARKET_TYPES.FIELD_GOAL_OVER_UNDER]: 'Total Field Goals',
  [MARKET_TYPES.FIRST_DOWN_OVER_UNDER]: 'Total 1st Downs Achieved',
  [MARKET_TYPES.FIRST_DOWN_RESULT]: 'Which Team Will Achieve The Most First Downs',
  [MARKET_TYPES.FIRST_PITCHER_TOTAL_STRIKEOUTS]: '{x} First Pitcher Total Strikeouts',
  [MARKET_TYPES.FIRST_SCORE_IS_A_TOUCHDOWN]: 'Will The First Score Be A Touchdown',
  [MARKET_TYPES.FOURTH_QUARTER_LEAD_CHANGE]: 'Will The Lead Change During the 4th Period',
  [MARKET_TYPES.GOAL_IN_TIME_BIN]: 'Goal To Be Score Within X and Y Minutes',
  [MARKET_TYPES.GOAL_MARGIN]: 'Winning Margin',
  [MARKET_TYPES.GOAL_MARGIN_EXC_OT]: 'Winning Margin (excl. OT)',
  [MARKET_TYPES.GOAL_ODD_EVEN_EXC_OT]: 'Total Odd/Even (excl. OT)',
  [MARKET_TYPES.GOAL_RACE_TO_X]: 'Race To {x}',
  [MARKET_TYPES.HALF_TIME_FULL_TIME_TIES_LOSE]: 'Half Time / Full Time (Ties Lose)',
  [MARKET_TYPES.LAST_GOAL]: '{x} To Score Last',
  [MARKET_TYPES.LAST_GOALSCORER]: 'Last Goalscorer',
  [MARKET_TYPES.LAST_SCORE_IS_A_TOUCHDOWN]: 'Will The Last Score Be A Touchdown',
  [MARKET_TYPES.LONGEST_DRIVE_YARDS_OVER_UNDER]: 'What Will The Distance Of The Longest Drive Be In Yards',
  [MARKET_TYPES.LONGEST_FIELD_GOAL_YARDS_OVER_UNDER]: 'What Will The Distance Of The Longest Made Field Goal Be In Yards',
  [MARKET_TYPES.NEXT_CONVERSION]: 'Will The Next Conversion Be Successful',
  [MARKET_TYPES.NEXT_FIELD_GOAL_ATTEMPT_IS_SUCCESSFUL]: 'Will The {x} Field Goal Attempt Be Successful',
  [MARKET_TYPES.NEXT_FIELD_GOAL_ATTEMPT_SUCCESSFUL]: 'Will The {x} Field Goal Attempt Be Successful',
  [MARKET_TYPES.NEXT_FIELD_GOAL_MADE]: 'Will The {x} Field Goal Attempt Be Successful',
  [MARKET_TYPES.NEXT_GOAL]: 'Next Goal Scored By',
  [MARKET_TYPES.NEXT_GOALSCORER]: 'Next Goalscorer',
  [MARKET_TYPES.NEXT_KICKOFF_TOUCHBACK]: 'Will The Next Kick-Off Result In A Touchback',
  [MARKET_TYPES.NEXT_PLAY_IS_A_FIRST_DOWN]: 'Will The {x} Play Be A 1st Down',
  [MARKET_TYPES.NEXT_PLAY_TOUCHDOWN]: 'Will The {x} Play Result In A Touchdown',
  [MARKET_TYPES.NEXT_PLAY_TYPE]: 'What Will The {x} Play Be',
  [MARKET_TYPES.NEXT_POSSESSION_OUTCOME]: 'Next Possession Result Two-Play',
  [MARKET_TYPES.NEXT_POSSESSION_OUTCOME_EXACT]: 'Next Possession Outcome',
  [MARKET_TYPES.NEXT_PUNT_FAIR_CATCH]: 'Will The Next {x} Result In A Fair Catch',
  [MARKET_TYPES.PERIOD_CORRECT_SCORE]: 'Period Correct Score',
  [MARKET_TYPES.PERIOD_GOAL_OVER_UNDER_FIRST_X_MINUTES]: '{x} Period - Goal In First X Minutes',
  [MARKET_TYPES.PERIOD_PLAYER_POINT_BANDS]: '{x} Period {z} ({y}) Total Bands',
  [MARKET_TYPES.PERIOD_PLAYER_POINT_OVER_UNDER]: '{x} Period {z} ({y}) Total Points',
  [MARKET_TYPES.PERIOD_PLAYER_THREE_POINTER_OVER_UNDER]: '{x} Period {z} ({y}) Total 3 Pointers',
  [MARKET_TYPES.PERIOD_POINT_HANDICAP_EXC_OT]: '{x} Period Spread (excl OT)',
  [MARKET_TYPES.PERIOD_POINT_OVER_UNDER_EXC_OT]: '{x} Period Total (excl OT)',
  [MARKET_TYPES.PERIOD_RESULT_EXC_OT]: '{x} Period Moneyline (excl OT)',
  [MARKET_TYPES.PERIOD_RESULT_FULL_TIME]: '{x} Period Result / Full-Time',
  [MARKET_TYPES.PERIOD_RUN_HANDICAP]: '{x} Inning Spread',
  [MARKET_TYPES.PERIOD_RUN_OVER_UNDER]: '{x} Inning Total',
  [MARKET_TYPES.PITCH_OUTCOME]: 'Pitch Result',
  [MARKET_TYPES.PITCH_SPEED_BANDED]: 'Pitch Speed Banded',
  [MARKET_TYPES.PITCH_SPEED_OVER_UNDER]: 'Pitch Speed Over/Under',
  [MARKET_TYPES.PLATE_APPEARANCE_PITCHES_OVER_UNDER]: 'Plate Appearance Pitch Count',
  [MARKET_TYPES.PLATE_APPEARANCE_RESULT_EXACT]: 'Plate Appearance Result Exact',
  [MARKET_TYPES.PLATE_APPEARANCE_RESULT_GROUPED]: 'Plate Appearance Result Grouped',
  [MARKET_TYPES.PLATE_APPEARANCE_RESULT_ON_BASE]: 'Plate Appearance Result On Base',
  [MARKET_TYPES.PLAYER_BASES_OVER_UNDER]: '{y} ({x}) Total Bases',
  [MARKET_TYPES.PLAYER_HOME_RUN]: '{y} ({x}) Home Run',
  [MARKET_TYPES.PLAYER_ON_BASE]: '{y} ({x}) To Get On Base',
  [MARKET_TYPES.PLAYER_ON_BASE_TWO_PLUS]: '{y} ({x}) To Get On Base Twice Or More',
  [MARKET_TYPES.PLAYER_PASSING_TOUCHDOWNS_OVER_UNDER]: '{y} ({x}) Total Passing Touchdowns',
  [MARKET_TYPES.PLAYER_RBI]: '{y} ({x}) To Record A RBI',
  [MARKET_TYPES.PLAYER_RUN_OVER_UNDER]: '{y} ({x}) Total Runs',
  [MARKET_TYPES.PLAYER_RUSHING_AND_PASSING_YARDS_OVER_UNDER]: '{y} ({x}) Total Rushing + Passing Yards',
  [MARKET_TYPES.PLAYER_SHOTS_OVER_UNDER]: '{y} ({x}) Total Shots',
  [MARKET_TYPES.PLAYER_STOLEN_BASE]: '{y} ({x}) To Steal A Base',
  [MARKET_TYPES.PLAYER_STRIKEOUT_OVER_UNDER]: '{y} ({x}) Total Strikeouts',
  [MARKET_TYPES.PLAYER_TOUCHDOWN_X_OR_MORE]: '{y} ({x}) To Score X Or More Touchdowns',
  [MARKET_TYPES.PUNT_OVER_UNDER]: 'Total Punts',
  [MARKET_TYPES.PUNT_YARDS_OVER_UNDER]: '{x} Punt Total Distance',
  [MARKET_TYPES.RESULT_AFTER]: 'To Be Winning After {x} Inning',
  [MARKET_TYPES.TWO_WAY_RESULT_AFTER]: 'To Be Winning After {x} Inning',
  [MARKET_TYPES.RUN_HANDICAP]: 'Spread',
  [MARKET_TYPES.RUN_HANDICAP_AFTER]: 'Spread After {x} Inning',
  [MARKET_TYPES.RUN_MARGIN]: 'Winning Margin',
  [MARKET_TYPES.RUN_OVER_UNDER]: 'Total',
  [MARKET_TYPES.RUN_OVER_UNDER_AFTER]: 'Total After {x} Inning',
  [MARKET_TYPES.SAFETY_SCORED]: 'Will Either Team Score A Safety',
  [MARKET_TYPES.SECOND_HALF_LEAD_CHANGE]: 'Will The Lead Change During the 2nd Half',
  [MARKET_TYPES.STRIKEOUT_OVER_UNDER]: 'Total Strikeouts',
  [MARKET_TYPES.SUCCESSFUL_TWO_POINT_CONVERSION]: 'Will Either Team Have A Successful 2 Point Conversion',
  [MARKET_TYPES.TEAM_FIRST_TO_SCORE]: 'Score First',
  [MARKET_TYPES.TEAM_LAST_TO_SCORE]: 'Which Team Will Score Last?',
  [MARKET_TYPES.TEAM_NEXT_BASKET_TYPE]: '{x} Next Field Goal Type',
  [MARKET_TYPES.TEAM_TO_SCORE_NEXT_BASKET]: 'Team To Score Next Basket',
  [MARKET_TYPES.TEAM_NEXT_TO_SCORE]: 'Team To Score Next ({x} Point)',
  [MARKET_TYPES.TEAM_OVER_UNDER]: '{x} Total Of Full Game',
  [MARKET_TYPES.TEAM_PERIOD_BALLS_IN_PLAY]: '{x} Inning {y} - Total Balls In Play Banded',
  [MARKET_TYPES.TEAM_PERIOD_BALLS_IN_PLAY_OVER_UNDER]: '{x} Inning {y} - Total Balls In Play',
  [MARKET_TYPES.TEAM_PERIOD_BATTERS_TO_PLATE]: '{x} Inning {y} - Total Batters To Plate Banded',
  [MARKET_TYPES.TEAM_PERIOD_BATTERS_TO_PLATE_OVER_UNDER]: '{x} Inning {y} - Total Batters To Plate',
  [MARKET_TYPES.TEAM_PERIOD_HITS]: '{x} Inning {y} - Total Hits Banded',
  [MARKET_TYPES.TEAM_PERIOD_HITS_OVER_UNDER]: '{x} Inning {y} - Total Hits',
  [MARKET_TYPES.TEAM_PERIOD_HOME_RUN]: '{x} Inning {y} - Home Run',
  [MARKET_TYPES.TEAM_PERIOD_PITCHES_BANDED]: '{x} Inning {y} - Total Pitches Banded',
  [MARKET_TYPES.TEAM_PERIOD_PITCHES_OVER_UNDER]: '{x} Inning {y} - Total Pitches',
  [MARKET_TYPES.TEAM_PERIOD_POINT_OVER_UNDER_EXC_OT]: '{x} Total Of Period',
  [MARKET_TYPES.TEAM_PERIOD_RUN_OVER_UNDER]: '{x} Inning {y} Total',
  [MARKET_TYPES.TEAM_PERIOD_RUN_SCORED]: '{x} Inning {y} - Run Scored',
  [MARKET_TYPES.TEAM_PERIOD_STRIKEOUT_OVER_UNDER]: '{x} Inning {y} - Total Strikeouts',
  [MARKET_TYPES.TEAM_PERIOD_THREE_BATTERS_REACH_BASE]: '{x} Inning {y} - Three Batters To Reach Base',
  [MARKET_TYPES.TEAM_PERIOD_THREE_BATTERS_STRIKEOUT]: '{x} Inning {y} - Three Batters To Strikeout',
  [MARKET_TYPES.TEAM_PERIOD_TO_SCORE]: '{x} Period {y} To Score',
  [MARKET_TYPES.TEAM_RUN_OVER_UNDER]: '{x} Total',
  [MARKET_TYPES.TEAM_RUN_OVER_UNDER_AFTER]: '{x} Total After Inning',
  [MARKET_TYPES.TEAM_SCORE_IN_EACH_PERIOD]: '{x} To Score In Each Period',
  [MARKET_TYPES.TEAM_STACK_ATTACK_OVER_UNDER]: '{x} Stack Attack',
  [MARKET_TYPES.TEAM_STRIKEOUT_OVER_UNDER]: '{x} Total Strikeouts',
  [MARKET_TYPES.TEAM_TO_SCORE_FIRST_WINS_MATCH]: 'Will The Team That Scores First Win The Game?',
  [MARKET_TYPES.TEAM_TO_SCORE_IN_OWN_DRIVE]: '{x} To Score in Their {y} Drive',
  [MARKET_TYPES.TEAM_TO_SCORE_LAST_WINS_MATCH]: 'Will The Team Scoring Last Win The Match',
  [MARKET_TYPES.TIE_AFTER_NIL_NIL]: 'Will The Scores Tie During The Match After 0-0',
  [MARKET_TYPES.TIME_OF_FIRST_SCORE]: 'Time Of First Score',
  [MARKET_TYPES.TOUCHDOWN_IN_TIME_BIN]: 'Touchdown To Be Scored Within {x} and {y} Minute',
  [MARKET_TYPES.TOUCHDOWN_OVER_UNDER]: 'Total Touchdowns',
  [MARKET_TYPES.TO_GO_THREE_AND_OUT]: '{x} Punt To Go 3 And Out',
  [MARKET_TYPES.TURNOVER_OVER_UNDER]: 'Total Turnovers (Interceptions And Fumbles Will Be Comitted)',
  [MARKET_TYPES.WIN_TO_NIL]: 'Team To Win To Nil',
  [MARKET_TYPES.LAST_POINT]: 'Last Point',
  [MARKET_TYPES.FIRST_SCORER_WINS_MATCH]: 'Will Team That Scores First Win The Match',

  // 30.5. added
  [MARKET_TYPES.FIELD_GOAL_ODD_EVEN]: 'Field Goals Odd / Even',
  [MARKET_TYPES.FIELD_GOAL_OVER_UNDER]: 'Total Field Goals',
  [MARKET_TYPES.THREE_WAY_FIELD_GOAL_OVER_UNDER]: 'Total Field Goals',
  [MARKET_TYPES.THREE_WAY_FIELD_GOAL_RESULT]: 'Field Goals Winner',
  [MARKET_TYPES.TWO_WAY_FIELD_GOAL_RESULT]: 'Field Goals Winner',
  [MARKET_TYPES.HALF_FIELD_GOAL_ODD_EVEN]: '{x} Half Field Goals Odd / Even',
  [MARKET_TYPES.HALF_FIELD_GOAL_OVER_UNDER]: '{x} Half Total Field Goals',
  [MARKET_TYPES.THREE_WAY_HALF_FIELD_GOAL_OVER_UNDER]: '{x} Half Total Field Goals',
  [MARKET_TYPES.HALF_FIELD_GOAL_RESULT]: '{x} Half Field Goals Winner',
  [MARKET_TYPES.THREE_WAY_HALF_FIELD_GOAL_RESULT]: '{x} Half Field Goals Winner',
  [MARKET_TYPES.PERIOD_FIELD_GOAL_ODD_EVEN]: '{x} Quarter Field Goals Odd / Even',
  [MARKET_TYPES.PERIOD_FIELD_GOAL_OVER_UNDER]: '{x} Quarter Total Field Goals',
  [MARKET_TYPES.THREE_WAY_PERIOD_FIELD_GOAL_OVER_UNDER]: '{x} Quarter Total Field Goals',
  [MARKET_TYPES.PERIOD_FIELD_GOAL_RESULT]: '{x} Quarter Field Goals Winner',
  [MARKET_TYPES.THREE_WAY_PERIOD_FIELD_GOAL_RESULT]: '{x} Quarter Field Goals Winner',
  [MARKET_TYPES.TEAM_HALF_FIELD_GOAL_OVER_UNDER]: '{x} Half {y} Total Field Goals',
  [MARKET_TYPES.THREE_WAY_TEAM_HALF_FIELD_GOAL_OVER_UNDER]: '{x} Half {y} Total Field Goals',
  [MARKET_TYPES.TEAM_PERIOD_FIELD_GOAL_OVER_UNDER]: '{x} Quarter {y} Total Field Goals',
  [MARKET_TYPES.THREE_WAY_TEAM_PERIOD_FIELD_GOAL_OVER_UNDER]: '{x} Quarter {y} Total Field Goals',
  [MARKET_TYPES.POINT_HANDICAP_EXC_OT]: 'Point Spread (Excl. Overtime)',
  [MARKET_TYPES.POINT_OVER_UNDER_EXC_OT]: 'Total Points (Excl. Overtime)',
  [MARKET_TYPES.THREE_WAY_POINT_OVER_UNDER_EXC_OT]: 'Total Points (Excl. Overtime)',
  [MARKET_TYPES.THREE_WAY_TEAM_POINT_OVER_UNDER]: '{x} Total Points',
  [MARKET_TYPES.HALF_POINT_HANDICAP_EXC_OT]: '{x} Half Point Spread (Excl. Overtime)',
  [MARKET_TYPES.THREE_WAY_HALF_POINT_HANDICAP_EXC_OT]: '{x} Half Point Spread (Excl. Overtime)',
  [MARKET_TYPES.THREE_WAY_PERIOD_POINT_HANDICAP_EXC_OT]: '{x} Quarter Point Spread (Excl. Overtime)',
  [MARKET_TYPES.HALF_POINT_OVER_UNDER_EXC_OT]: '{x} Half Total Points (Excl. Overtime)',
  [MARKET_TYPES.THREE_WAY_HALF_POINT_OVER_UNDER_EXC_OT]: '{x} Half Total Points (Excl. Overtime)',
  [MARKET_TYPES.HALF_RESULT_EXC_OT]: '{x} Half Winner (Excl. Overtime)',
  [MARKET_TYPES.TWO_WAY_HALF_RESULT_EXC_OT]: '{x} Half Winner (Excl. Overtime)',
  [MARKET_TYPES.THREE_WAY_PERIOD_POINT_OVER_UNDER_EXC_OT]: '{x} Quarter Total Points (Excl. Overtime)',
  [MARKET_TYPES.TWO_WAY_PERIOD_RESULT_EXC_OT]: '{x} Quarter Winner (Excl. Overtime)',
  [MARKET_TYPES.THREE_WAY_TEAM_HALF_POINT_OVER_UNDER]: '{x} Half {y} Total Points',
  [MARKET_TYPES.TEAM_HALF_TOUCHDOWN_OVER_UNDER]: '{x} Half {y} Total Touchdowns',
  [MARKET_TYPES.THREE_WAY_TEAM_HALF_TOUCHDOWN_OVER_UNDER]: '{x} Half {y} Total Touchdowns',
  [MARKET_TYPES.THREE_WAY_TEAM_PERIOD_POINT_OVER_UNDER]: '{x} Quarter {y} Total Points',
  [MARKET_TYPES.TEAM_PERIOD_TOUCHDOWN_OVER_UNDER]: '{x} Quarter {y} Total Touchdowns',
  [MARKET_TYPES.THREE_WAY_TEAM_PERIOD_TOUCHDOWN_OVER_UNDER]: '{x} Quarter {y} Total Touchdowns',
  [MARKET_TYPES.THREE_WAY_TOUCHDOWN_OVER_UNDER]: 'Total Touchdowns',
  [MARKET_TYPES.TEAM_TOUCHDOWN_OVER_UNDER]: '{x} Total Touchdowns',
  [MARKET_TYPES.THREE_WAY_TEAM_TOUCHDOWN_OVER_UNDER]: '{x} Total Touchdowns',
  [MARKET_TYPES.PERIOD_TOUCHDOWN_OVER_UNDER]: '{x} Quarter Total Touchdowns',
  [MARKET_TYPES.THREE_WAY_PERIOD_TOUCHDOWN_OVER_UNDER]: '{x} Quarter Total Touchdowns',
  [MARKET_TYPES.HALF_TOUCHDOWN_OVER_UNDER]: '{x} Half Total Touchdowns',
  [MARKET_TYPES.THREE_WAY_HALF_TOUCHDOWN_OVER_UNDER]: '{x} Half Total Touchdowns',
  [MARKET_TYPES.HALF_TOUCHDOWN_ODD_EVEN]: '{x} Half Touchdowns Odd / Even',
  [MARKET_TYPES.HALF_TOUCHDOWN_RESULT]: '{x} Half Touchdowns Winner',
  [MARKET_TYPES.THREE_WAY_HALF_TOUCHDOWN_RESULT]: '{x} Half Touchdowns Winner',
  [MARKET_TYPES.PERIOD_TOUCHDOWN_ODD_EVEN]: '{x} Quarter Touchdowns Odd / Even',
  [MARKET_TYPES.PERIOD_TOUCHDOWN_RESULT]: '{x} Quarter Touchdowns Winner',
  [MARKET_TYPES.THREE_WAY_TOUCHDOWN_RESULT]: '{x} Touchdowns Winner',
  [MARKET_TYPES.TWO_WAY_TOUCHDOWN_RESULT]: '{x} Touchdowns Winner',
  [MARKET_TYPES.TOUCHDOWN_HIGHEST_SCORING_HALF]: 'Highest Touchdown Scoring Half',
  [MARKET_TYPES.TOUCHDOWN_HIGHEST_SCORING_PERIOD]: 'Highest Touchdown Scoring Quarter',
  [MARKET_TYPES.TOUCHDOWN_ODD_EVEN]: 'Touchdown Odd / Even',
  [MARKET_TYPES.MOST_POINTS_IN_SECOND_QUARTER]: 'Will The 2nd Quarter Be The Highest Scoring One',

  // new markets 11.8.2023. NCAAF
  [MARKET_TYPES.BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_QUARTER]: 'Both Teams To Score In Each Quarter',
  [MARKET_TYPES.POINTS_IN_EACH_QUARTER]: 'Points In Each Quarter',
  [MARKET_TYPES.FIRST_SCORING_PLAY_TYPE]: 'First Scoring Type',
  [MARKET_TYPES.TEAM_TO_LEAD_AFTER_EACH_QUARTER]: '{x} To Lead After Each Quarter',
  [MARKET_TYPES.TEAM_TO_SCORE_X_OR_MORE_POINTS]: '{x} To Score',
  [MARKET_TYPES.BOTH_TEAMS_TO_SCORE_X_OR_MORE_POINTS]: 'Both Teams To Score',
  [MARKET_TYPES.PLAYER_TO_SCORE_FIRST_TOUCHDOWN_AND_TEAM_TO_WIN]: '{y} ({x}) To Score 1st TD & {x} To Win',
  [MARKET_TYPES.PLAYER_TO_SCORE_TOUCHDOWN_AND_TEAM_TO_WIN]: '{y} ({x}) To Score A TD & {x} To Win',
  [MARKET_TYPES.LOWEST_SCORING_HALF]: 'Lowest Scoring Half',
  [MARKET_TYPES.LOWEST_SCORING_PERIOD]: 'Lowest Scoring Quarter',
  [MARKET_TYPES.BOTH_TEAMS_TO_SCORE_FIELD_GOAL]: 'Both Teams To Score a Field Goal',
  [MARKET_TYPES.BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS]: 'Both Teams To Score Touchdowns & {x} Field Goals',
  [MARKET_TYPES.FIELD_GOAL_IN_EACH_PERIOD]: 'Field Goal Scored In Every Quarter',
  [MARKET_TYPES.TOUCHDOWN_IN_EACH_PERIOD]: 'Touchdown Scored In Every Quarter',
  [MARKET_TYPES.FIRST_SECOND_HALF_SCORING_PLAY_TYPE]: '2nd Half First Scoring Type',
  [MARKET_TYPES.TEAM_FIRST_SCORING_PLAY_TYPE]: '{x} First Scoring Type',
  [MARKET_TYPES.FIRST_FIELD_GOAL_YARDAGE_OVER_UNDER]: 'First Field Goal Yardage',
  [MARKET_TYPES.FIRST_SCORE_YARDAGE_OVER_UNDER]: 'First Score Yardage',
  [MARKET_TYPES.FIRST_TOUCHDOWN_YARDAGE_OVER_UNDER]: 'First Touchdown Yardage',
  [MARKET_TYPES.TEAM_NEXT_DRIVE_OUTCOME]: '{x} Outcome Of {y} Drive', // team, drive_index
  [MARKET_TYPES.TEAM_TO_SCORE_FIRST_FIELD_GOAL]: 'First Team To Score A Field Goal',
  [MARKET_TYPES.TEAM_TO_SCORE_FIRST_TOUCHDOWN]: 'First Team To Score A Touchdown',
  [MARKET_TYPES.BOTH_TEAMS_TO_SCORE_IN_X_PERIOD]: '{x} Quarter - Both Teams To Score In',
  [MARKET_TYPES.BOTH_TEAMS_TO_SCORE_POINTS_IN_EACH_HALF]: 'Both Teams To Score In Both Halves',
  [MARKET_TYPES.BOTH_TEAMS_TO_SCORE_X_OR_MORE_FIELD_GOALS]: 'Both Teams To Score X Field Goals',
  [MARKET_TYPES.BOTH_TEAMS_TO_SCORE_X_OR_MORE_TOUCHDOWNS]: 'Both Teams To Score X Touchdowns',
  [MARKET_TYPES.LARGEST_LEAD_OF_GAME]: 'Largest Lead',
  [MARKET_TYPES.LAST_SCORING_PLAY_TYPE]: 'Last Scoring Play',
  [MARKET_TYPES.HALF_TEAM_LAST_TO_SCORE]: 'Last Team To Score In 1st Half',
  [MARKET_TYPES.LONGEST_TOUCHDOWN_SCORED]: 'Longest Touchdown Yardage',
  [MARKET_TYPES.PERIOD_OF_FIRST_FIELD_GOAL]: 'Quarter of First Field Goal',
  [MARKET_TYPES.PERIOD_OF_FIRST_TOUCHDOWN]: 'Quarter of First Touchdown',
  [MARKET_TYPES.TOUCHDOWN_IN_PERIOD]: '{x} Quarter Touchdown Scored',
  [MARKET_TYPES.TEAM_NUMBER_OF_HALVES_WON]: '{x} Number Of Halves Won',
  [MARKET_TYPES.TEAM_NUMBER_OF_PERIODS_WON]: '{x} Number Of Quarters Won',
  [MARKET_TYPES.TEAM_TO_SCORE_LONGEST_FIELD_GOAL]: 'Team To Score The Longest Field Goal',
  [MARKET_TYPES.TEAM_TO_SCORE_LONGEST_TOUCHDOWN]: 'Team To Score The Longest Touchdown',
  [MARKET_TYPES.POINT_TOTAL_BANDS]: 'Total Bands',
  [MARKET_TYPES.NEXT_PLAY_OFFENSIVE_TOUCHDOWN]: '{y} Play on {x} Drive to End in an Offensive Touchdown', // play, drive_index
  [MARKET_TYPES.POINT_MARGIN_BANDS]: 'Winning Margin',
  [MARKET_TYPES.FIELD_GOAL_RESULT]: 'Most Field Goals',
  [MARKET_TYPES.TOUCHDOWN_RESULT]: 'Most Touchdowns',
  [MARKET_TYPES.RESULT_AND_OVER_UNDER]: 'Moneyline and Total',
  [MARKET_TYPES.TEAM_TO_SCORE_FIRST_LOSES_MATCH]: 'Team To Score First To Lose The Match',

  // Micro markets added 15.09.2023.
  [MARKET_TYPES.CURRENT_DRIVE_OUTCOME]: 'Outcome Of {y} Drive For {x}',
  [MARKET_TYPES.CURRENT_DRIVE_OUTCOME_GROUPED]: 'Grouped Outcome Of {y} Drive For {x}',
  [MARKET_TYPES.NEXT_DRIVE_OUTCOME]: 'Outcome Of {y} Drive For {x}',
  [MARKET_TYPES.NEXT_DRIVE_OUTCOME_GROUPED]: 'Grouped Outcome Of {y} Drive For {x}',
  [MARKET_TYPES.CURRENT_DRIVE_TEAM_TO_GO_THREE_AND_OUT]: '{x} To Go 3 And Out On {y} Drive',
  [MARKET_TYPES.NEXT_DRIVE_TEAM_TO_GO_THREE_AND_OUT]: '{x} To Go 3 And Out On {y} Drive',
  [MARKET_TYPES.SITUATIONAL_EXTRA_POINT_CONVERSION_MADE]: 'Extra Point Conversion To Be Made After {x} Score',
  [MARKET_TYPES.SITUATIONAL_FIELD_GOAL_ATTEMPT_MADE]: 'Field Goal To Be Made On The {y} Play Of The {x} Drive',
  [MARKET_TYPES.SITUATIONAL_TWO_POINTS_CONVERSION_MADE]: 'Two Point Conversion To Be Made After {x} Score',

  [MARKET_TYPES.PUNT_TOTAL_YARDS_OVER_UNDER]: '{x} Punt Total Yards',
  [MARKET_TYPES.NEXT_PLAY_IS_FIRST_DOWN]: '{y} Play in {x} Drive is 1st down',
  [MARKET_TYPES.PERIOD_TEAM_TO_SCORE_FIRST]: '{x} Period Team To Score First',
  [MARKET_TYPES.SCORE_IN_LAST_TWO_MINUTES_OF_HALF]: 'Score In Last 2 Minutes Of {x} Half',
  [MARKET_TYPES.BOTH_TEAMS_X_OR_MORE_TOUCHDOWNS_AND_X_OR_MORE_FIELD_GOALS_ONE_LINE]: 'Both Teams X Or More Touchdowns and X Or More Field Goals On Same Line',

  [MARKET_TYPES.MOST_POINTS_IN_PERIOD]: 'Most Points Will be in {x} Quarter',
};

const SELECTION_SORT_MAPPINGS = {
  HOME: 100,
  HOME_USA: 135,
  OVER: 110,
  DRAW: 120,
  AWAY: 130,
  AWAY_USA: 105,
  UNDER: 140,
  HOME_HOME: 300,
  HOME_DRAW: 305,
  HOME_AWAY: 310,
  DRAW_HOME: 320,
  DRAW_DRAW: 325,
  DRAW_AWAY: 330,
  AWAY_HOME: 340,
  AWAY_DRAW: 345,
  AWAY_AWAY: 350,
  FIELD_GOAL: 351,
  NO_FIELD_GOAL: 352,
  OPPOSITION_TOUCHDOWN: 353,
  ANY_DRAW: 354,
  ANY_TURNOVER: 355,
  ANY_OTHER: 356,
  TWO_POINTER: 356,
  THREE_POINTER: 359,
  HOME_TWO_POINTER: 357,
  HOME_THREE_POINTER: 358,
  AWAY_TWO_POINTER: 359,
  AWAY_THREE_POINTER: 360,
  AWAY_TWO_POINTER_USA: 375,
  AWAY_THREE_POINTER_USA: 376,
  HOME_TWO_POINTER_USA: 377,
  HOME_THREE_POINTER_USA: 378,
  FREE_THROW: 362,
  NO_SCORE: 363,
  CORRECT_SCORE_HOME: 365,
  CORRECT_SCORE_AWAY: 367,
  CORRECT_SCORE_AWAY_USA: 368,
  CORRECT_SCORE_HOME_USA: 369,
};

const marketOptionsOrderMapper = {
  soccer: {
    RESULT: () => 1,
    RESULT_EXC_OT: () => 1,
    GOAL_OVER_UNDER: () => 2,
    HALF_RESULT: () => 3,
    PERIOD_RESULT: () => 4,
  },
  default: {
    RESULT: () => 1,
    TWO_WAY_RESULT_EXC_OT: () => 2,
    POINT_HANDICAP: () => 2,
    RUN_HANDICAP: () => 2,
    GOAL_OVER_UNDER: () => 2,
    GOAL_HANDICAP: () => 3,
    POINT_OVER_UNDER: () => 3,
    RUN_OVER_UNDER: () => 3,
    RESULT_EXC_OT: () => 4,
    MATCH_RESULT_AND_RUNS_OVER_UNDER: () => 4,
    HALF_RESULT: () => 5,
    HALF_POINT_HANDICAP: () => 6,
    HALF_POINT_OVER_UNDER: () => 7,
    PERIOD_RESULT: () => 8,
    PERIOD_POINT_HANDICAP: () => 9,
    PERIOD_POINT_OVER_UNDER: () => 10,
    PERIOD_RUN_HANDICAP: () => 9,
    PERIOD_RUN_OVER_UNDER: () => 10,
    PERIOD_GOAL_HANDICAP: () => 9,
    PERIOD_GOAL_OVER_UNDER: () => 10,
    TEAM_POINT_OVER_UNDER: () => 11,
    TEAM_GOAL_OVER_UNDER: () => 11,
    TEAM_HALF_POINT_OVER_UNDER: () => 12,
    TEAM_PERIOD_POINT_OVER_UNDER: () => 13,
    TO_GO_TO_OVERTIME: () => 14,
    DOUBLE_RESULT: () => 15,
    HALF_TIME_FULL_TIME: () => 16,
    HALF_TIME_FULL_TIME_EXC_OT: () => 16,
    NEXT_POINT: () => 17,
    HIGHEST_SCORING_HALF: () => 18,
    HIGHEST_SCORING_PERIOD: () => 19,
    POINTS_ODD_EVEN: () => 20,
    HALF_POINTS_ODD_EVEN: () => 21,
    PERIOD_POINTS_ODD_EVEN: () => 22,
  },
};

const mapNumberSuffix = (num) => {
  let numberSuffix = '';
  switch (num.slice(-1)) {
  case '1':
    numberSuffix = 'st';
    break;
  case '2':
    numberSuffix = 'nd';
    break;
  case '3':
    numberSuffix = 'rd';
    break;
  default:
    numberSuffix = 'th';
    break;
  }
  switch (num.slice(-2)) {
  case '11':
  case '12':
  case '13':
    numberSuffix = 'th';
    break;
  default:
    break;
  }
  return numberSuffix;
};

const teamFromIndex = (index) => (!index ? 'Home' : 'Away');

const getNumberWithSuffix = (num) => `${num}${mapNumberSuffix(num)}`;

const SELECTION_MAPPINGS = {
  HOME: () => 'Home',
  DRAW: () => 'Draw',
  AWAY: () => 'Away',
  OVER: () => 'Over',
  UNDER: () => 'Under',
  ODD: () => 'Odd',
  EVEN: () => 'Even',
  NEITHER: () => 'Neither',
  ANY_DRAW: () => 'Any Draw',
  FIELD_GOAL: () => 'Field Goal',
  NO_FIELD_GOAL: () => 'No Field Goal',
  OPPOSITION_TOUCHDOWN: () => 'Opposition Touchdown',
  ANY_OTHER: () => 'Any Other',
  ANY_TURNOVER: () => 'Any Turnover',
  YES: () => 'Yes',
  NO: () => 'No',
  HOME_HOME: () => 'Home/Home',
  AWAY_AWAY: () => 'Away/Away',
  DRAW_DRAW: () => 'Draw/Draw',
  HOME_AWAY: () => 'Home/Away',
  AWAY_HOME: () => 'Away/Home',
  HOME_DRAW: () => 'Home/Draw',
  DRAW_HOME: () => 'Draw/Home',
  AWAY_DRAW: () => 'Away/Draw',
  DRAW_AWAY: () => 'Draw/Away',
  HALF: (selection) => `${getNumberWithSuffix(selection.selectionType.params.HALF_X?.toString())} Half`,
  PERIOD: (selection) => `${getNumberWithSuffix(selection.selectionType.params.PERIOD_X?.toString())} Period`,
  MARGIN: (selection) => `${teamFromIndex(selection.selectionType.params.TEAM)} by ${selection.selectionType.params.LOWER_BOUND}-${selection.selectionType.params.UPPER_BOUND}`,
  MARGIN_AND_ABOVE: (selection) => `${teamFromIndex(selection.selectionType.params.TEAM)} by ${selection.selectionType.params.LOWER_BOUND}+`,
  MARGIN_EXACT: (selection) => `${teamFromIndex(selection.selectionType.params.TEAM)} by ${selection.selectionType.params.MARGIN}`,
  BAND: (selection) => `${selection.selectionType.params.LOWER_BOUND}-${selection.selectionType.params.UPPER_BOUND}`,
  BAND_AND_ABOVE: (selection) => `${selection.selectionType.params.LOWER_BOUND}+`,
  CORRECT_SCORE: (selection, isUsaView) => (isUsaView
    ? `${selection.selectionType.params.CORRECT_SCORE_AWAY}-${selection.selectionType.params.CORRECT_SCORE_HOME}`
    : `${selection.selectionType.params.CORRECT_SCORE_HOME}-${selection.selectionType.params.CORRECT_SCORE_AWAY}`),
  TWO_POINTER: () => '2 Points',
  HOME_TWO_POINTER: () => 'Home 2 Points',
  AWAY_TWO_POINTER: () => 'Away 2 Points',
  THREE_POINTER: () => '3 Points',
  HOME_THREE_POINTER: () => 'Home 3 Points',
  AWAY_THREE_POINTER: () => 'Away 3 Points',
  FREE_THROW: () => 'Free Throws',
  NO_SCORE: () => 'No Basket',
  IN_PLAY: () => 'Ball In Play',
  DOUBLE_OR_TRIPLE: () => 'Double or Triple',
  HOME_RUN: () => 'Home Run',
  IN_PLAY_OUT: () => 'Out',
  EXTRA_BASE_HIT: () => 'Extra Base Hit',
  SINGLE_OR_WALK: () => 'Single or Walk',
  GOALS: (selection) => `${selection.selectionType.params.GOALS_X} ${selection.selectionType.params.GOALS_X === 1 ? 'Goal' : 'Goals'}`,
};

const marketName = ({ marketCode }, sport) => {
  let name = MARKET_MAPPINGS[marketCode] || marketCode;

  if (sport?.sportLabel !== 'ICE_HOCKEY' && sport?.sportLabel !== 'BASEBALL') {
    name = name.replace('Period', 'Quarter');
  }

  if (sport?.sportLabel === 'BASEBALL') {
    name = name.replace('Period', 'Inning');
  }

  return name;
};

const selectionName = (selection, sport, isUsaView) => {
  const selectionCode = selection?.selectionType?.selectionCode || 'N/A';
  let name = SELECTION_MAPPINGS[selectionCode] ? SELECTION_MAPPINGS[selectionCode](selection, isUsaView) : selectionCode;

  const sportLabel = sport?.sportLabel || sport?.label || '';

  if (sportLabel !== 'ICE_HOCKEY' && sportLabel !== 'BASEBALL') {
    name = name.replace('Period', 'Quarter').replace('PERIOD', 'QUARTER');
  }

  if (sportLabel === 'BASEBALL') {
    name = name.replace('Period', 'Inning').replace('PERIOD', 'INNING');
  }

  return name;
};

const sortMarkets = (markets, isUsaView) => {
  const teamOrder = isUsaView ? 'desc' : 'asc';
  return orderBy(markets,
    ['marketType.params.PERIOD', 'marketType.params.HALF', 'marketType.params.TEAM', 'marketType.params.PLAYER_INDEX',
      'marketType.params.RESULT_AFTER_X', 'marketType.params.RACE_TO', 'marketSummary.isMainLine', 'marketType.params.LINE', 'marketType.params.SCORE_INDEX',
      'marketType.params.DRIVE_INDEX', 'marketType.params.BIN_MINUTE_START', 'marketType.params.PUNT', 'marketType.params.PLAY',
      'marketType.params.FIELD_GOAL', 'marketType.params.POSSESSION', 'marketType.params.YARD_LINE', 'marketType.params.PITCH_INDEX',
      'marketType.params.PLATE_APPEARANCE', 'marketType.params.BATTER_PLAYER_INDEX', 'marketType.params.PITCHER_PLAYER_INDEX', 'marketType.params.IS_TOP_INNING',
      'marketType.params.TEAM_SCORE_AT_CREATION', 'marketType.params.HOME_SCORE_AT_CREATION', 'marketType.params.AWAY_SCORE_AT_CREATION', 'marketType.params.MILESTONE_YARDS'],
    ['asc', 'asc', teamOrder, 'asc',
      'asc', 'asc', 'desc', 'asc', 'asc',
      'asc', 'asc', 'asc', 'asc',
      'asc', 'asc', 'asc', 'asc',
      'asc', 'asc', 'asc', 'asc',
      'asc', 'asc', 'asc', 'asc']);
};

const sortSelections = ({ selections, isUsaView }) => {
  if (!selections || !isArray(selections)) return undefined;
  if (find(selections, (selection) => selection?.selectionType?.params?.HALF_X)) {
    return orderBy(selections, 'selectionType.params.HALF_X');
  }
  if (find(selections, (selection) => selection?.selectionType?.params?.PERIOD_X)) {
    return orderBy(selections, 'selectionType.params.PERIOD_X');
  }
  if (find(selections, (selection) => selection?.selectionType?.params?.GOALS_X)) {
    return orderBy(selections, 'selectionType.params.GOALS_X');
  }
  if (find(selections, (selection) => selection?.selectionType?.params?.LOWER_BOUND)) {
    const teamOrder = isUsaView ? 'desc' : 'asc';
    return orderBy(selections, ['selectionType.params.TEAM', 'selectionType.params.MARGIN', 'selectionType.params.LOWER_BOUND'], [teamOrder, 'asc', 'asc']);
  }
  if (find(selections, (selection) => selection?.selectionType?.params?.CORRECT_SCORE_HOME)) {
    return !isUsaView
      ? orderBy(selections, ['selectionType.params.CORRECT_SCORE_HOME', 'selectionType.params.CORRECT_SCORE_AWAY'])
      : orderBy(selections, ['selectionType.params.CORRECT_SCORE_AWAY', 'selectionType.params.CORRECT_SCORE_HOME']);
  }
  return sortBy(selections, (selection) => {
    if (isUsaView && includes(['HOME', 'AWAY', 'HOME_TWO_POINTER', 'HOME_THREE_POINTER', 'AWAY_TWO_POINTER', 'AWAY_THREE_POINTER', 'CORRECT_SCORE_HOME', 'CORRECT_SCORE_AWAY'],
    selection?.selectionType?.selectionCode)) {
      return SELECTION_SORT_MAPPINGS[`${selection?.selectionType?.selectionCode}_USA`];
    }
    return SELECTION_SORT_MAPPINGS[selection?.selectionType?.selectionCode];
  });
};

const formatMarketGroups = (marketGroups, marketNodes, sport) => map(marketGroups, (marketGroup) => {
  if (marketGroup.code === 'ALL') { return marketGroup; }
  let { displayName } = marketGroup;
  const markets = map(marketGroup.markets.nodes, (market) => market.marketCode);
  const matchingMarkets = filter(markets, (marketCode) => includes(map(marketNodes, (node) => node.marketCode), marketCode)).length;

  if (sport?.sportLabel !== 'ICE_HOCKEY' && displayName === 'Period') {
    displayName = 'Quarter';
  }

  return {
    ...marketGroup,
    displayName,
    matchingMarkets,
    markets,
  };
});

const marketParamReplaceIndexes = {
  1: 'x', 2: 'y', 3: 'z', 4: 'w',
};

// Major param types are the ones that can combine with each other and with some minor param types so we need to check them separately (ex: 1st Half Team Race to Points)
const majorMarketParamTypes = (marketObj, event) => {
  let market = marketObj;
  if (market.marketType.params.PERIOD || market.marketType.params.HALF) {
    const value = market.marketType.params.PERIOD || market.marketType.params.HALF;
    const paramValue = `${value}${mapNumberSuffix(value.toString())}`;
    market = assign({}, market, {
      paramsToReplace: {
        x: {
          paramValue,
        },
      },
    });
  }
  if (!isNil(market.marketType.params.TEAM)) {
    const nextTeamParamField = !market.paramsToReplace ? 'x' : marketParamReplaceIndexes[Object.keys(market.paramsToReplace).length + 1];
    market = assign({}, market, {
      paramsToReplace: {
        ...market.paramsToReplace,
        [nextTeamParamField]: {
          paramValue: event.competitors[market.marketType.params.TEAM]?.name,
        },
      },
    });
  }
  if (!isNil(market.marketType.params.PLAYER_INDEX)) {
    const nextPlayerParamField = !market.paramsToReplace ? 'x' : marketParamReplaceIndexes[Object.keys(market.paramsToReplace).length + 1];
    market = assign({}, market, {
      paramsToReplace: {
        ...market.paramsToReplace,
        [nextPlayerParamField]: {
          paramValue: startCase(toLower(market.marketSummary?.playerName)) || 'N/A Player',
        },
      },
    });
  }
  return market;
};

// Minor param types are the ones that dont combine with each other and only possible combine with major param types
const minorMarketParamTypes = (marketObj) => {
  let market = marketObj;
  const marketParams = market.marketType.params;

  if (!marketParams.RACE_TO && !marketParams.SCORE_INDEX && !marketParams.DRIVE_INDEX && !marketParams.PLAY && !marketParams.PUNT
      && !marketParams.FIELD_GOAL && !marketParams.RESULT_AFTER_X && !marketParams.BIN_MINUTE_START && !marketParams.YARD_LINE) {
    return market;
  }
  const value = marketParams.RACE_TO || marketParams.SCORE_INDEX || marketParams.DRIVE_INDEX || marketParams.PLAY || marketParams.PUNT
    || marketParams.FIELD_GOAL || marketParams.RESULT_AFTER_X || marketParams.BIN_MINUTE_START || marketParams.YARD_LINE;

  // Race to markets dont have number suffix (ex. Race to 10 points), others have (ex. Score 2nd point)
  const paramValue = marketParams.RACE_TO ? value : `${value}${mapNumberSuffix(value.toString())}`;

  const nextParamField = !market.paramsToReplace ? 'x' : marketParamReplaceIndexes[Object.keys(market.paramsToReplace).length + 1];
  market = assign({}, market, {
    paramsToReplace: {
      ...market.paramsToReplace,
      [nextParamField]: {
        paramValue,
      },
    },
  });
  // Bin minute start end market types dont combine with major types so always have x and y params only
  if (marketParams.BIN_MINUTE_END) {
    market = assign({}, market, {
      paramsToReplace: {
        ...market.paramsToReplace,
        y: {
          paramValue: `${marketParams.BIN_MINUTE_END}${mapNumberSuffix(marketParams.BIN_MINUTE_END.toString())}`,
        },
      },
    });
  }
  if (marketParams.DRIVE_INDEX && marketParams.PLAY) {
    market = assign({}, market, {
      paramsToReplace: {
        ...market.paramsToReplace,
        y: {
          paramValue: `${marketParams.PLAY}${mapNumberSuffix(marketParams.PLAY.toString())}`,
        },
      },
    });
  }
  if (marketParams.DRIVE_INDEX && marketParams.YARD_LINE) { // (team, drive_index, yard_line params combo)
    market = assign({}, market, {
      paramsToReplace: {
        ...market.paramsToReplace,
        z: {
          paramValue: `${marketParams.YARD_LINE}${mapNumberSuffix(marketParams.YARD_LINE.toString())}`,
        },
      },
    });
  }
  return market;
};

const mapInternalMarketsForDisplay = (markets, event) => {
  let mappedMarkets = map(markets, (marketObj) => {
    let market = marketObj;
    market = majorMarketParamTypes(market, event); // Create period, team and player prop params
    market = minorMarketParamTypes(market); // Create minor params

    const { marketCode } = market;
    let marketDisplayName = marketName({ marketCode }, { sportLabel: event.sport.sportLabel });

    if (market.paramsToReplace) {
      marketDisplayName = marketDisplayName.replace('{x}', market.paramsToReplace.x?.paramValue);
      marketDisplayName = marketDisplayName.replace('{y}', market.paramsToReplace.y?.paramValue);
      marketDisplayName = marketDisplayName.replace('{z}', market.paramsToReplace.z?.paramValue);
    }
    return { ...market, displayName: marketDisplayName, selections: sortSelections({ selections: market.selections, isUsaView: event.isUsaView }) };
  });
  mappedMarkets = map(mappedMarkets, (market) => {
    if (find(mappedMarkets,
      (mappedMarket) => market.displayName === mappedMarket.displayName && market.marketType.params.SELECTIONS !== mappedMarket.marketType.params.SELECTIONS)) {
      return {
        ...market,
        displayName: `${market.displayName} ${market.marketType.params.SELECTIONS}-Way`,
      };
    }
    return market;
  });
  return sortBy(sortMarkets(mappedMarkets),
    (marketOption) => {
      const marketOptionValue = marketOptionsOrderMapper.default[marketOption.marketCode] || (() => 9999);
      return marketOptionValue(marketOption);
    });
};

export default {
  marketName,
  sortMarkets,
  sortSelections,
  marketOptionsOrderMapper,
  formatMarketGroups,
  mapInternalMarketsForDisplay,
  mapNumberSuffix,
  selectionName,
};
