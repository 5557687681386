import {
  indexOf,
  cloneDeep,
  assign,
} from 'lodash';
import { formatMinutesAndSeconds } from '@/utils/number';

const PERIODS = [
  'PRE_GAME',
  'FIRST_PERIOD',
  'BEFORE_SECOND_PERIOD',
  'SECOND_PERIOD',
  'BEFORE_THIRD_PERIOD',
  'THIRD_PERIOD',
  'BEFORE_OVERTIME',
  'OVERTIME_1',
  'OVERTIME_2',
  'OVERTIME_3',
  'OVERTIME_4',
  'OVERTIME_5',
  'OVERTIME_6',
  'OVERTIME_7',
  'OVERTIME_8',
  'OVERTIME_9',
  'OVERTIME_10',
  'BEFORE_PENALTY_SHOOTOUT',
  'PENALTY_SHOOTOUT',
  'POST_GAME',
  'GAME_ABANDONED',
  'SUSPENDED',
];

const PERIOD_ORDINAL_MAPPER = {
  TOTAL: 'Match',
  FIRST_PERIOD: '1st period',
  SECOND_PERIOD: '2nd period',
  THIRD_PERIOD: '3rd period',
  OVERTIME_1: 'Overtime',
  OVERTIME_2: '2nd overtime',
  OVERTIME_3: '3rd overtime',
  OVERTIME_4: '4th overtime',
  OVERTIME_5: '5th overtime',
  OVERTIME_6: '6th overtime',
  OVERTIME_7: '7th overtime',
  OVERTIME_8: '8th overtime',
  OVERTIME_9: '9th overtime',
  OVERTIME_10: '10th overtime',
  PENALTY_SHOOTOUT: 'Penalties',
};

const PERIOD_SHORT_ORDINAL_MAPPER = {
  TOTAL: 'Match',
  FIRST_PERIOD: '1st P',
  SECOND_PERIOD: '2nd P',
  THIRD_PERIOD: '3rd P',
  OVERTIME_1: 'OT',
  OVERTIME_2: 'OT2',
  OVERTIME_3: 'OT3',
  OVERTIME_4: 'OT4',
  OVERTIME_5: 'OT5',
  OVERTIME_6: 'OT6',
  OVERTIME_7: 'OT7',
  OVERTIME_8: 'OT8',
  OVERTIME_9: 'OT9',
  OVERTIME_10: 'OT10',
  PENALTY_SHOOTOUT: 'Pens',
};

const NON_LIVE_PERIODS = [
  'PRE_GAME',
  'POST_GAME',
  'GAME_ABANDONED',
];

const OVER_PERIODS = [
  'POST_GAME',
];

const livePeriods = [
  'FIRST_PERIOD',
  'BEFORE_SECOND_PERIOD',
  'SECOND_PERIOD',
  'BEFORE_THIRD_PERIOD',
  'THIRD_PERIOD',
  'BEFORE_OVERTIME',
  'OVERTIME_1',
  'OVERTIME_2',
  'OVERTIME_3',
  'OVERTIME_4',
  'OVERTIME_5',
  'OVERTIME_6',
  'OVERTIME_7',
  'OVERTIME_8',
  'OVERTIME_9',
  'OVERTIME_10',
  'BEFORE_PENALTY_SHOOTOUT',
  'PENALTY_SHOOTOUT',
];

const getLivePeriods = () => [...livePeriods];

const getPeriodAbbreviation = (inPlayPeriodCode) => {
  switch (inPlayPeriodCode) {
  case 'FIRST_PERIOD':
    return '1st P';
  case 'SECOND_PERIOD':
    return '2nd P';
  case 'THIRD_PERIOD':
    return '3rd P';
  case 'OVERTIME_1':
    return 'OT';
  case 'OVERTIME_2':
    return 'OT2';
  case 'OVERTIME_3':
    return 'OT3';
  case 'OVERTIME_4':
    return 'OT4';
  case 'OVERTIME_5':
    return 'OT5';
  case 'OVERTIME_6':
    return 'OT6';
  case 'OVERTIME_7':
    return 'OT7';
  case 'OVERTIME_8':
    return 'OT8';
  case 'OVERTIME_9':
    return 'OT9';
  case 'OVERTIME_10':
    return 'OT19';
  case 'PENALTY_SHOOTOUT':
    return 'Pens';
  default:
    return '';
  }
};

const getPeriodOrdinal = (inPlayPeriodCode) => PERIOD_ORDINAL_MAPPER[inPlayPeriodCode];

const getPeriodShortOrdinal = (inPlayPeriodCode) => PERIOD_SHORT_ORDINAL_MAPPER[inPlayPeriodCode];

const getPeriodTime = ({ secondsLeftInPeriod }) => formatMinutesAndSeconds(secondsLeftInPeriod);

const getPeriodLabel = ({
  period,
  periodLengthInMinutes,
  overtimeLengthInMinutes,
  secondsLeftInPeriod,
}, {
  shortLabel = false,
} = {}) => {
  if (!period) return '';

  const time = getPeriodTime({
    period,
    periodLengthInMinutes,
    overtimeLengthInMinutes,
    secondsLeftInPeriod,
  });

  switch (period) {
  case 'FIRST_PERIOD':
    return shortLabel ? `P1 • ${time}` : `1. PER • ${time}`;
  case 'SECOND_PERIOD':
    return shortLabel ? `P2 • ${time}` : `2. PER • ${time}`;
  case 'THIRD_PERIOD':
    return shortLabel ? `P3 • ${time}` : `3. PER • ${time}`;
  case 'OVERTIME_1':
    return shortLabel ? `OT • ${time}` : `OT • ${time}`;
  case 'OVERTIME_2':
    return shortLabel ? `OT2 • ${time}` : `2. OT • ${time}`;
  case 'OVERTIME_3':
    return shortLabel ? `OT3 • ${time}` : `3. OT • ${time}`;
  case 'OVERTIME_4':
    return shortLabel ? `OT4 • ${time}` : `4. OT • ${time}`;
  case 'OVERTIME_5':
    return shortLabel ? `OT5 • ${time}` : `5. OT • ${time}`;
  case 'OVERTIME_6':
    return shortLabel ? `OT6 • ${time}` : `6. OT • ${time}`;
  case 'OVERTIME_7':
    return shortLabel ? `OT7 • ${time}` : `7. OT • ${time}`;
  case 'OVERTIME_8':
    return shortLabel ? `OT8 • ${time}` : `8. OT • ${time}`;
  case 'OVERTIME_9':
    return shortLabel ? `OT9 • ${time}` : `9. OT • ${time}`;
  case 'OVERTIME_10':
    return shortLabel ? `OT10 • ${time}` : `10. OT • ${time}`;
  case 'PENALTY_SHOOTOUT':
    return shortLabel ? 'Pens' : 'Penalty SO';
  case 'BEFORE_SECOND_PERIOD':
  case 'BEFORE_THIRD_PERIOD':
  case 'BEFORE_OVERTIME':
  case 'BEFORE_PENALTY_SHOOTOUT':
    return 'Break';
  case 'POST_GAME':
    return 'End';
  case 'GAME_ABANDONED':
    return 'Suspended';
  default:
    return '';
  }
};

const getPeriodIndex = (periodCode) => {
  if (periodCode === 'TOTAL') return 0;
  return indexOf(PERIODS, periodCode) + 1;
};

const isActivePeriod = (periodCode) => !!PERIOD_ORDINAL_MAPPER[periodCode];

const updateEvent = (eventDetails, payload) => {
  const updatedEventDetails = cloneDeep(eventDetails);

  assign(
    updatedEventDetails,
    {
      ...updatedEventDetails,
      period: payload.state.currentClock?.period,
      periods: payload.rules.periods,
      secondsLeftInPeriod: payload.state.currentClock?.secondsLeftInPeriod,
      periodLengthInMinutes: payload.rules.periodLengthInMinutes,
      overtimeLengthInMinutes: payload.rules.otLengthInMinutes,
      isOvertimePossible: payload.rules.otPossible,
      isClockRunning: payload.state.currentClock?.clockRunning,
      teamInPossession: payload.state.teamInPossession,
      timeOfLastClockStart: payload.state.currentClock?.timeOfLastClockStart,
      eventInBreak: payload.state.eventInBreak,
      eventPreMatch: payload.state.eventPreMatch,
      homeScore: payload.state.homeScore,
      awayScore: payload.state.awayScore,
      homeLineup: payload.competitors?.homeTeam?.lineup,
      awayLineup: payload.competitors?.awayTeam?.lineup,
      scorePerPeriod: payload.state.scorePerPeriod,
      foulsPerPeriod: payload.state.penaltiesPerPeriod,
      actions: payload.state.actions,
    },
  );

  return updatedEventDetails;
};

export default {
  PERIODS,
  NON_LIVE_PERIODS,
  OVER_PERIODS,
  getPeriodOrdinal,
  getPeriodShortOrdinal,
  getPeriodAbbreviation,
  getPeriodTime,
  getPeriodLabel,
  getPeriodIndex,
  isActivePeriod,
  updateEvent,
  getLivePeriods,
};
